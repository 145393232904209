import { useAtomValue } from "jotai";
import {
  orderDeliveryPrice,
  orderProductList,
  orderSalePrice,
} from "../../../contents/orderTestData";
import OrderAmountItem from "../../molecules/order/orderAmountItem";
import OrderElementLabel from "../../molecules/order/orderElementLabel";
import { orderProductPriceAtom } from "../../../stores/order/atoms";

const OrderAmountTotal = () => {
  let orderProductPrice = 0;
  orderProductList.map((value) => {
    return (orderProductPrice += value.price);
  });

  const productPrice = useAtomValue(orderProductPriceAtom);

  return (
    <div className="flex flex-col pt-[32px]">
      <OrderElementLabel label="최종 결제 금액" src="/svgs/order/pay.svg" />
      <div className="pt-[16px] space-y-[16px] border-t mt-[16px] flex flex-col">
        <OrderAmountItem
          label="할인 금액"
          value={`${orderSalePrice.toLocaleString("ko-KR")} 원`}
        />
        <OrderAmountItem
          label="총 결제 금액"
          value={`${(
            productPrice -
            orderSalePrice +
            orderDeliveryPrice
          ).toLocaleString("ko-KR")} 원`}
          color={true}
        />
        <button
          onClick={() => {}}
          className="bg-[#EB6143] text-white text-[16px] rounded-[8px] h-[54px]"
        >
          쿠폰 사용
        </button>
      </div>
    </div>
  );
};

export default OrderAmountTotal;
