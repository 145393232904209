import { useSearchParams } from "react-router-dom";

import OrderElementLabel from "../../molecules/order/orderElementLabel";
import OrderProductItem from "../../molecules/order/orderProductItem";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { imageAtom, recommendTitleAtom } from "../../../stores/recommend/atoms";

import { useEffect } from "react";
import {
  orderIdAtom,
  orderProductCountAtom,
  orderProductNameAtom,
  orderProductPriceAtom,
} from "../../../stores/order/atoms";

const OrderProductList = () => {
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");
  const amount = searchParams.get("amount");
  const image = useAtomValue(imageAtom);
  const setOrderId = useSetAtom(orderIdAtom);
  const name = useAtomValue(recommendTitleAtom);
  const [productName, setProductName] = useAtom(orderProductNameAtom);
  const [productCount, setProductCount] = useAtom(orderProductCountAtom);
  const [productPrice, setProductPrice] = useAtom(orderProductPriceAtom);

  const getOrderInfo = async () => {
    if (orderId) {
      setOrderId(orderId);
      setProductName(name);
      setProductCount(1);
      setProductPrice(Number(amount));
    }
  };

  useEffect(() => {
    getOrderInfo();
  }, []);

  return (
    <div className="space-y-[8px]">
      <OrderElementLabel label={`내 결제 (${1}건)`} />
      <div className="border border-[#E2E2E2] rounded-[8px] divide-y divide-[#E2E2E2] px-[16px]">
        <OrderProductItem
          // brand={value.brand}
          name={productName}
          price={productPrice}
          count={productCount}
          image={
            image
              ? URL.createObjectURL(image)
              : "/images/defaultDiffuserImage.jpeg"
          }
        />
      </div>
    </div>
  );
};

export default OrderProductList;
