import { atom } from "jotai";

/// 단계
const recommendLevelAtom = atom<number>(0);

/// Regist 데이터
const recommendTitleAtom = atom<string>("");
const perfumeProductAtom = atom<
  Array<{ name: string; brand: string }> | undefined
>();
const seasonalCheckedAtom = atom<Array<boolean>>([false, false, false, false]);
const fragranceTasteAtom = atom<Array<string | undefined>>([]);
const appearanceAtom = atom<Array<string | undefined>>([]);
const storyAtom = atom<string>("");
const imageAtom = atom<File | undefined>();

export {
  recommendLevelAtom,
  recommendTitleAtom,
  perfumeProductAtom,
  seasonalCheckedAtom,
  fragranceTasteAtom,
  appearanceAtom,
  storyAtom,
  imageAtom,
};
