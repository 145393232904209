import { ReactNode } from "react";
import Layout from "../layout";

interface defaultProps {
  header: ReactNode;
  contents: ReactNode;
}

const LoginTemplate = ({ header, contents }: defaultProps) => {
  return (
    <>
      <div className="flex flex-col w-full h-full">
        {header}
        {contents}
      </div>
    </>
  );
};

export default LoginTemplate;
