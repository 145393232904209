import Label from "../../atoms/label";

interface defaultProps {
  label: string;
  count: number;
  countType: string;
}

const CouponElement = ({ label, count, countType }: defaultProps) => {
  return (
    <div className="p-2 flex flex-col items-center w-[50%]">
      <Label className="text-[12px] text-[#787878]">{label}</Label>
      <div className="flex flex-row items-center justify-center space-x-1">
        <Label className="text-[20px] text-[#787878]">{count}</Label>
        <Label className="text-[12px] text-[#787878]">{countType}</Label>
      </div>
    </div>
  );
};

export default CouponElement;
