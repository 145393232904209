import OrderElementLabel from "../../molecules/order/orderElementLabel";
import OrderInputElement from "../../molecules/order/orderInputElement";
import { useDaumPostcodePopup } from "react-daum-postcode";
import OrderAddressElement from "../../molecules/order/orderAddressElement";
import { useAtom, useAtomValue } from "jotai";
import {
  customerNameAtom,
  customerPhoneAtom,
  deliveryAddressAtom,
  deliveryAddressDetailAtom,
  deliveryMemoAtom,
  deliveryNameAtom,
  deliveryPhoneAtom,
  deliveryZipCodeAtom,
} from "../../../stores/order/atoms";
import { useEffect, useState } from "react";
import Image from "../../atoms/image";
import Label from "../../atoms/label";

const OrderAddressInput = () => {
  const [deliveryName, setDeliveryName] = useAtom(deliveryNameAtom);
  const [deliveryMemo, setDeliveryMemo] = useAtom(deliveryMemoAtom);
  const [deliveryPhone, setDeliveryPhone] = useAtom(deliveryPhoneAtom);
  const [deliveryZipCode, setDeliveryZipCode] = useAtom(deliveryZipCodeAtom);
  const [deliveryAddress, setDeliveryAddress] = useAtom(deliveryAddressAtom);
  const [deliveryAddressDetail, setDeliveryAddressDetail] = useAtom(
    deliveryAddressDetailAtom
  );

  const customerName = useAtomValue(customerNameAtom);
  const customerPhone = useAtomValue(customerPhoneAtom);

  const [copyInfo, setCopyInfo] = useState<boolean>(false);

  /// 데이터를 전역으로 관리하기 때문에 페이지에 접근했을 때 초기화 진행
  useEffect(() => {
    setDeliveryName("");
    setDeliveryMemo("");
    setDeliveryPhone("");
    setDeliveryAddress("");
    setDeliveryAddressDetail("");
  }, []);

  const handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    setState: React.Dispatch<any>
  ) => {
    setState(event.target.value);
  };

  const open = useDaumPostcodePopup();

  const handleComplete = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = "";

    console.log();

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }

    setDeliveryAddress(fullAddress);
    setDeliveryZipCode(data.zonecode);
  };

  const handleButtonClick = () => {
    open({ onComplete: handleComplete });
  };

  const handleCopyCustomerInfo = () => {
    if (!copyInfo) {
      setDeliveryName(customerName);
      setDeliveryPhone(customerPhone);
    }
    setCopyInfo(!copyInfo);
  };

  return (
    <div className="pt-[32px]">
      <OrderElementLabel
        label="수령자 정보 입력"
        src="/svgs/order/delivery.svg"
      />
      <div className="pt-[16px] space-y-[16px] border-t mt-[16px]">
        {/* 주문자와 정보가 동일한지 체크하는 checkBox 추가 */}
        <OrderCheckBox
          label="주문자와 정보가 동일해요"
          check={copyInfo}
          handleClick={handleCopyCustomerInfo}
        />
        <OrderInputElement
          label="성함"
          handleInputChange={(event) => {
            handleInputChange(event, setDeliveryName);
          }}
          value={deliveryName}
          placeholder="성함을 입력해주세요."
          essential={true}
        />
        <OrderAddressElement
          zipCode={deliveryZipCode}
          address={deliveryAddress}
          addressDetail={deliveryAddressDetail}
          handleInputChange={(event) => {
            handleInputChange(event, setDeliveryAddressDetail);
          }}
          handleButtonClick={handleButtonClick}
        />
        <OrderInputElement
          label="휴대폰 번호"
          handleInputChange={(event) => {
            handleInputChange(event, setDeliveryPhone);
          }}
          value={deliveryPhone}
          placeholder="- 없이 입력해주세요."
          essential={true}
        />
        <OrderInputElement
          label="배송 메모"
          handleInputChange={(event) => {
            handleInputChange(event, setDeliveryMemo);
          }}
          value={deliveryMemo}
          placeholder="메모를 입력해주세요."
          essential={true}
        />
      </div>
    </div>
  );
};

interface defaultProps {
  check: boolean;
  label: string;
  handleClick: () => void;
}
const OrderCheckBox = ({ check, label, handleClick }: defaultProps) => {
  return (
    <div className="flex flex-row items-center">
      <div onClick={handleClick} className="mx-1">
        <Image
          src={
            check
              ? "/svgs/order/checkBoxChecked.svg"
              : "/svgs/order/checkBox.svg"
          }
        />
      </div>
      <Label>{label}</Label>
    </div>
  );
};

export default OrderAddressInput;
