import { ReactNode } from "react";
import Layout from "../layout";

interface defaultProps {
  recommendLoading: ReactNode;
}

const RecommendAcceptTemplate = ({ recommendLoading }: defaultProps) => {
  return (
    <>
      <div className="flex flex-col w-full h-full pt-[59px]">
        {recommendLoading}
      </div>
    </>
  );
};

export default RecommendAcceptTemplate;
