import Image from "../../atoms/image";
import Label from "../../atoms/label";

const OrderAcceptGraphics = () => {
  return (
    <div className="w-full h-[345px] flex flex-col justify-end items-center">
      <div className="flex flex-col justify-end items-center w-full grow bg-gradient-to-b from-[#FF7300] to-white pb-[16px]">
        <Image src="/svgs/orderAccept/paymentsAccept.svg" width={140} />
      </div>
      <Label className="text-[#FF7300] text-[24px] font-bold tracking-tighter mt-[16px]">
        결제가 완료되었습니다!
      </Label>
    </div>
  );
};

export default OrderAcceptGraphics;
