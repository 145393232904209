import Label from "../../atoms/label";

interface defaultProps {
  label: string;
  value: string;
  color?: boolean;
}

const OrderAmountItem = ({ label, value, color = false }: defaultProps) => {
  return (
    <div
      className={`flex flex-row justify-between items-center rounded-[8px] px-[16px] py-[12px] ${
        color
          ? "bg-[#FF7300] text-[#FFFFFF]"
          : "border border-[#A6A6A6] bg-white text-black"
      }  `}
    >
      <Label>{label}</Label>
      <Label>{value}</Label>
    </div>
  );
};

export default OrderAmountItem;
