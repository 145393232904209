import { atom } from "jotai";

export const perfumeRecommendGenderAtom = atom<string>("");
export const perfumeRecommendStoryAtom = atom<string>("");
export const perfumeRecommendLevelAtom = atom<number>(0);
export const perfumeRecommendCommentAtom = [
  "시작해 볼까요?",
  "2단계 남았어요!",
  "마지막이에요!",
];
