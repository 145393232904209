export const seasonal = ["봄", "여름", "가을", "겨울"];

export const recommendLevelComment = [
  "시작해볼까요?",
  "7단계 남았어요!",
  "6단계 남았어요!",
  "5단계 남았어요!",
  "4단계 남았어요!",
  "3단계 남았어요!",
  "2단계 남았어요!",
  "마지막 단계에요!",
];

export const recommendButtonLabel = [
  "만들어볼까요?",
  "다음 단계로",
  "다음 단계로",
  "다음 단계로",
  "다음 단계로",
  "다음 단계로",
  "다음 단계로",
  "향기 담기",
];
