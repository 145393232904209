import { useCallback, useState } from "react";

interface naverAnalyticsHook {
  initialize: (id: string) => void;
  pageViewTrigger: () => void;
  eventTrigger: (category: string, label: string) => void;
}

/// 네이버 애널리틱스
export const useNaverAnalytics = (): naverAnalyticsHook => {
  const [trackingId, setTrackingId] = useState<string | null>(null);
  const [isScriptLoaded, setIsScriptLoaded] = useState<boolean>(false);
  const scriptId = "naver-analytics-script";

  const loadNaverAnalyticsScript = useCallback(() => {
    // 스크립트가 이미 로드되어 있지 않은 경우에만 추가
    if (!document.getElementById(scriptId) && trackingId) {
      // 스크립트 요소 생성
      const script = document.createElement("script");
      script.id = scriptId; // 중복 로드를 방지하기 위한 id 설정
      script.src = "//wcs.naver.net/wcslog.js"; // 네이버 애널리틱스 스크립트 URL 지정
      script.async = true; // 비동기 설정

      // 스크립트 로드 완료 후 추적 초기화
      script.onload = () => {
        setIsScriptLoaded(true);
        // window as any : 타입 체크 우회
        // (불러오는 네이버 애널리틱스 스크립트가 js로 되어있어서 ts에서 사용하기위해 필요함)
        if (!(window as any).wcs_add) {
          // wcs_add 초기화
          (window as any).wcs_add = {};
        }
        (window as any).wcs_add["wa"] = trackingId;
      };

      // 스크립트 요소 document head에 추가 -> 스크립트 로드됨.
      document.head.appendChild(script);
    }
  }, [trackingId]);

  /// trackingId를 이용하여 초기화 하는 매서드를 만들어서 사용하고자 함.
  const initialize = useCallback((id: string) => {
    setTrackingId(id);
    if (!isScriptLoaded) {
      loadNaverAnalyticsScript();
    }
  }, []);

  /// 페이지 접속을 추적하는 함수
  const pageViewTrigger = useCallback(() => {
    if (!isScriptLoaded) {
      loadNaverAnalyticsScript();
    }

    if ((window as any).wcs) {
      (window as any).wcs_do();
    }
  }, [isScriptLoaded, loadNaverAnalyticsScript]);

  /// 이벤트 발생을 추적하는 함수
  const eventTrigger = useCallback(
    (category: string, label: string) => {
      if (!isScriptLoaded) {
        loadNaverAnalyticsScript();
      }
      if ((window as any).wcs) {
        (window as any).wcs.event(category, label);
      }
    },
    [isScriptLoaded, loadNaverAnalyticsScript]
  );

  return { initialize, pageViewTrigger, eventTrigger };
};
