import RecommendButton from "../organisms/recommend/recommendButton";
import RecommendForm from "../organisms/recommend/recommendForm";
import RecommendLabel from "../organisms/recommend/recommendLabel";
import RecommendStatusBar from "../organisms/recommend/recommendStatusBar";
import RecommendTemplate from "../templates/recommendTemplate";

const Recommend = () => {
  return (
    <RecommendTemplate
      recommendStatusBar={<RecommendStatusBar />}
      contentDescription={<RecommendLabel />}
      recommendContent={<RecommendForm />}
      recommendButton={<RecommendButton />}
    ></RecommendTemplate>
  );
};

export default Recommend;
