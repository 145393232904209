import { ReactNode } from "react";
import Label from "../atoms/label";

interface defaultProps {
  children: ReactNode;
  label?: string;
}

const CustomOrderItem = ({ children, label }: defaultProps) => {
  return (
    <>
      <Label className="font-semibold text-xl">{label}</Label>
      {children}
    </>
  );
};

export default CustomOrderItem;
