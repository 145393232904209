import { privacyPolicy } from "../../contents/privacyPolicy";
import Layout from "../layout";
import ContentHeader from "../organisms/contentHeader";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <ContentHeader>개인정보 처리방침</ContentHeader>
      <div className="whitespace-pre-wrap p-4 text-[14px]">{privacyPolicy}</div>
    </Layout>
  );
};

export default PrivacyPolicy;
