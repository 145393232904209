import { ReactNode } from "react";
import Layout from "../layout";
import { recommendLevelAtom } from "../../stores/recommend/atoms";
import { useAtomValue } from "jotai";
import { motion, AnimatePresence } from "framer-motion";

interface defaultProps {
  recommendStatusBar: ReactNode;
  contentDescription: ReactNode;
  recommendContent: ReactNode;
  recommendButton: ReactNode;
}

const RecommendTemplate = ({
  recommendStatusBar,
  contentDescription,
  recommendContent,
  recommendButton,
}: defaultProps) => {
  const recommendLevel = useAtomValue(recommendLevelAtom);
  return (
    <>
      <div className="relative flex flex-col w-full h-full">
        {recommendStatusBar}
        <AnimatePresence mode="wait">
          <motion.div
            key={recommendLevel}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            {contentDescription}
            {recommendContent}
          </motion.div>
          {/* 아래에는 버튼 부분 들어가고, absolute를 이용해서 하단을 기준으로 배치 */}
        </AnimatePresence>
        {recommendButton}
      </div>
    </>
  );
};

export default RecommendTemplate;
