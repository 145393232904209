import { useEffect, useRef, useState } from "react";
import TagLabel from "../atoms/tagLabel";
import Input from "../atoms/input";
import { perfumeFindByName } from "../../api/perfumeFinder";
import Image from "../atoms/image";

interface defaultProps {
  searchInput: string;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
  perfumeProduct:
    | {
        name: string;
        brand: string;
      }[]
    | undefined;
  setPerfumeProduct: React.Dispatch<
    React.SetStateAction<
      | {
          name: string;
          brand: string;
        }[]
      | undefined
    >
  >;
}

interface perfume {
  id: number;
  name: string;
  brand: string;
}

const PerfumeFinder = ({
  searchInput,
  setSearchInput,
  perfumeProduct,
  setPerfumeProduct,
}: defaultProps) => {
  const [productList, setProductList] = useState<
    Array<{ id: number; name: string; brand: string }>
  >([]);
  const [searchMode, setSearchMode] = useState<boolean>(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const inputRef = useRef<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchInput(value);
    inputRef.current = value;

    // 이전 타이머가 있다면 취소
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // setSearchMode(false);

    if (value.trim().length >= 2) {
      setIsLoading(true);

      timerRef.current = setTimeout(async () => {
        try {
          const data: Array<perfume> = await perfumeFindByName(value);
          const newProductList = data.map((value, index) => ({
            id: index,
            name: value.name,
            brand: value.brand,
          }));
          setProductList(newProductList);
          setSearchMode(true);
        } catch (error) {
          console.error("Search error:", error);
          setProductList([]);
          setSearchMode(true);
        } finally {
          setIsLoading(false);
        }
      }, 200);
    } else {
      setProductList([]);
      setSearchMode(false);
      setIsLoading(false);
    }
  };

  // 컴포넌트 언마운트 시 타이머 정리
  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (searchInput.length < 1) setSearchMode(false);
    else setSearchMode(true);
  }, [searchMode, searchInput]);

  const handleClickProduct = (createIndex: number) => {
    const newPerfumeProduct: { name: string; brand: string } = {
      name: productList[createIndex].name,
      brand: productList[createIndex].brand,
    };

    // 중복 체크
    const isDuplicate = perfumeProduct?.some(
      (product) =>
        product.name === newPerfumeProduct.name &&
        product.brand === newPerfumeProduct.brand
    );

    if (!isDuplicate) {
      if (perfumeProduct) {
        setPerfumeProduct([...perfumeProduct, newPerfumeProduct]);
      } else {
        setPerfumeProduct([newPerfumeProduct]);
      }
    }

    setSearchInput("");
    setSearchMode(false);
  };

  const handleClickLabel = (deleteIndex: number) => {
    const newValue: Array<{
      name: string;
      brand: string;
    }> = [];
    perfumeProduct?.map((value, index) => {
      if (index !== deleteIndex) newValue.push(value);
      return 0;
    });

    if (newValue) setPerfumeProduct(newValue);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && searchInput.trim()) {
      const isCompleteKorean = /^[가-힣a-zA-Z0-9\s]+$/.test(searchInput);

      if (isCompleteKorean && searchInput.length > 1) {
        const newProduct = {
          name: searchInput,
          brand: "",
        };

        if (perfumeProduct) {
          // 중복 체크
          const isDuplicate = perfumeProduct.some(
            (product) => product.name === searchInput
          );

          if (!isDuplicate) {
            setPerfumeProduct([...perfumeProduct, newProduct]);
          }
        } else {
          setPerfumeProduct([newProduct]);
        }

        // 입력창 초기화 및 검색 모드 종료
        setSearchInput("");
        setTimeout(() => {
          setSearchInput("");
        }, 100);
        setSearchMode(false);
      }
    }
  };

  const ProductListItem = ({
    index,
    name,
    brand,
    handleClick,
  }: {
    index: number;
    name: string;
    brand: string;
    handleClick: (createIndex: number) => void;
  }) => {
    return (
      <div
        className="h-[50px] flex flex-col justify-center px-4"
        onClick={(event) => {
          event.stopPropagation();
          handleClick(index);
        }}
      >
        <div className="text-md font-bold">{brand}</div>
        <div className="text-xl">{name}</div>
      </div>
    );
  };

  return (
    <>
      <div className="px-5 h-[50px] bg-[#D9D9D9] relative">
        <div className="w-full h-full flex flex-row items-center">
          <Image width={18} height={18} src="/svgs/search.svg" />
          <Input
            value={searchInput}
            handleInputChange={handleSearchChange}
            handleKeyDown={handleKeyPress}
            placeholder="이 곳에서 향수 이름을 입력해 주세요."
          />
        </div>
        {searchMode && (
          <div className="bg-[#EEEEEE] absolute top-[50px] left-0 right-0 w-full min-h-[40px] max-h-[40dvh] rounded-b-2xl overflow-scroll divide-y divide-black">
            {isLoading ? (
              <div className="flex justify-center py-4">검색중...</div>
            ) : productList.length ? (
              productList.map((value, index) => (
                <ProductListItem
                  key={index}
                  index={index}
                  name={value.name}
                  brand={value.brand}
                  handleClick={handleClickProduct}
                />
              ))
            ) : (
              <div className="flex justify-center text-gray-500 py-4">
                검색 결과가 없습니다.
              </div>
            )}
          </div>
        )}
      </div>
      <div
        className={`flex flex-row flex-wrap justify-start content-start pt-1 mt-5
    ${perfumeProduct?.length ? "" : "hidden"}`}
      >
        {perfumeProduct?.map((value, index) => {
          return (
            <div>
              <TagLabel
                key={index}
                index={index}
                handleClickLabel={handleClickLabel}
              >
                {`${value.brand} ${value.name}`}
              </TagLabel>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default PerfumeFinder;
