import { useAtom, useAtomValue } from "jotai";
import { recommendLevelAtom } from "../../../stores/recommend/atoms";
import { userImageAtom, userNameAtom } from "../../../stores/user/atoms";
import Label from "../../atoms/label";
import { AnimatePresence, motion } from "framer-motion";
import { getUserInfo } from "../../../api/customOrder";
import { useEffect } from "react";

const RecommendLabel = () => {
  const [userName, setUserName] = useAtom(userNameAtom);
  const [userImage, setUserImage] = useAtom(userImageAtom);

  const getUser = async () => {
    if (!userName) {
      const data = await getUserInfo();
      console.log(data);
      setUserName(data.nickname);
      setUserImage(data.profile_image_url);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const recommendLevel = useAtomValue(recommendLevelAtom);
  const contents = [
    <Label className="text-[18px] font-bold min-h-[108px]">
      반갑습니다 {userName}님!
      <br />
      <br />딱 1분이면 {userName}님과
      <br />딱 맞는 향수를 AI가
      <br />
      만들어 줄게요!
    </Label>,
    <Label className="text-[20px] font-bold min-h-[100px]">
      이 향기의 이름을
      <br />
      무엇이라고 하시겠어요?
    </Label>,
    <Label className="text-[20px] font-bold min-h-[100px]">
      가지고 계신 향수 중
      <br />
      가장 마음에 드는 향수가
      <br />
      무엇인가요?
    </Label>,
    <Label className="text-[20px] font-bold min-h-[100px]">
      좋아하는 향의 특징이
      <br />
      무엇인가요?
    </Label>,
    <Label className="text-[20px] font-bold min-h-[100px]">
      나를 표현하는
      <br />
      키워드 단어는 무엇인가요?
    </Label>,
    <Label className="text-[20px] font-bold min-h-[60px]">
      좋아하는 계절을
      <br />
      알려주세요
    </Label>,
    <Label className="text-[20px] font-bold min-h-[60px]">
      향기에 담을 스토리를
      <br />
      알려주세요
    </Label>,
    <Label className="text-[20px] font-bold min-h-[100px]">
      마지막으로
      <br />
      본인이 좋아하는 이미지를
      <br />
      올려주세요
    </Label>,
  ];
  return (
    <div className="px-6 py-2">
      <AnimatePresence mode="wait">
        <motion.div
          key={recommendLevel}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{
            duration: 0.5,
            delay: 0.2,
            ease: "easeInOut",
          }}
        >
          {contents[recommendLevel]}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default RecommendLabel;
