import Element from "../../molecules/element";
import UserQuickMenuList from "../../molecules/user/userQuickMenuList";

const menuList = [
  { label: "주문조회", url: "/user/orderDetail" },
  { label: "개인정보처리방침", url: "/privacyPolicy" },
  // { label: "설정", url: "/setting" },
];

const UserQuickMenu = () => {
  return (
    <Element label="퀵 메뉴">
      <UserQuickMenuList menuList={menuList} />
    </Element>
  );
};

export default UserQuickMenu;
