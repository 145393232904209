import { ReactNode } from "react";

interface defaultProps {
  contentHeader: ReactNode;
  navigationBar: ReactNode;
  mainContent: ReactNode;
  mainFooter: ReactNode;
}

const MainTemplate = ({
  contentHeader,
  navigationBar,
  mainContent,
  mainFooter,
}: defaultProps) => {
  return (
    <>
      <div className="flex flex-col">
        {contentHeader}
        {mainContent}
        {mainFooter}
        {navigationBar}
      </div>
    </>
  );
};

export default MainTemplate;
