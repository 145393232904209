import { useSearchParams } from "react-router-dom";

const TossPaymentsFail = () => {
  const [searchParams] = useSearchParams();
  const errorCode = searchParams.get("code");
  const errorMessage = searchParams.get("message");

  return (
    <div className="flex flex-col items-center p-[24px] auto w-full">
      <div className="flex flex-col items-center w-full max-w-[540px]">
        <img
          src="https://static.toss.im/lotties/error-spot-apng.png"
          width="120"
          height="120"
          alt=""
        />
        <h2 className="mt-[32px] text-[24px] font-bold">결제를 실패했어요</h2>
        <div className="mt-[60px] flex flex-col gap-[16px] text-[20px] w-full">
          <div className="flex justify-between">
            <span className="font-semibold text-[#333d48] text-[17px]">
              code
            </span>
            <span
              id="error-code"
              className="font-medium text-[#4e5968] text-[17px] pl-[16px] text-center break-words"
            >
              {errorCode}
            </span>
          </div>
          <div className="flex justify-between">
            <span className="font-semibold text-[#333d48] text-[17px]">
              message
            </span>
            <span
              id="error-message"
              className="font-medium text-[#4e5968] text-[17px] pl-[16px] text-center break-words"
            >
              {errorMessage}
            </span>
          </div>
        </div>

        <div className="w-full mt-[32px] flex flex-col justify-center gap-[16px]">
          <a
            className="decoration-0 text-center py-[11px] px-[22px] rounded-[8px] bg-[#f2f4f6] text-[#4e5968] font-semibold text-[17px]"
            href="https://developers.tosspayments.com/sandbox"
            target="_blank"
            rel="noreferrer noopener"
          >
            다시 결제 시도
          </a>
          {/* 테스트 모드 전용 */}
          <a
            className="decoration-0 text-center py-[11px] px-[22px] rounded-[8px] bg-[#f2f4f6] text-[#4e5968] font-semibold text-[17px]"
            href="https://developers.tosspayments.com/sandbox"
            target="_blank"
            rel="noreferrer noopener"
          >
            다시 테스트하기
          </a>
          <div className="flex gap-[16px]">
            <a
              className="decoration-0 text-center py-[11px] px-[22px] rounded-[8px] bg-[#f2f4f6] text-[#4e5968] font-semibold text-[17px] w-full"
              href="https://docs.tosspayments.com/reference/error-codes"
              target="_blank"
              rel="noreferrer noopener"
            >
              에러코드 문서보기
            </a>
            <a
              className="decoration-0 text-center py-[11px] px-[22px] rounded-[8px] bg-[#f2f4f6] text-[#4e5968] font-semibold text-[17px] w-full"
              href="https://techchat.tosspayments.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              실시간 문의하기
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TossPaymentsFail;
