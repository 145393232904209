interface defaultProps {
  value: string;
  handleAreaChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder: string;
}

const AreaInput = ({ value, handleAreaChange, placeholder }: defaultProps) => {
  return (
    <textarea
      className="whitespace-pre-wrap text-lg outline-none p-4 w-full max-h-[500px] h-[45dvh] max-h-[480px] bg-white border border-black"
      value={value}
      onChange={handleAreaChange}
      placeholder={placeholder}
    ></textarea>
  );
};

export default AreaInput;
