import ContentHeader from "../organisms/contentHeader";
import LoginForm from "../organisms/loginForm";
import LoginTemplate from "../templates/loginTemplate";

const Login = () => {
  // 카카오 로그인
  const restApiKey = process.env.REACT_APP_OAUTH_KAKAO_REST_API_KEY;
  const redirectUri = process.env.REACT_APP_OAUTH_KAKAO_REDIRECT_URI;

  const handleLogin = () => {
    window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${restApiKey}&redirect_uri=${redirectUri}&response_type=code`;
  };

  return (
    <LoginTemplate
      header={<ContentHeader home={true}>로그인</ContentHeader>}
      contents={<LoginForm handleLogin={handleLogin}></LoginForm>}
    />
  );
};

export default Login;
