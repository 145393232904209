import { useNavigate, useSearchParams } from "react-router-dom";
import { receipt } from "../../../contents/orderReceipt";

const OrderAcceptButton = () => {
  const [params] = useSearchParams();
  const orderId = params.get("orderId");
  const navigate = useNavigate();
  const handleClickHome = () => {
    navigate("/");
  };
  const handleClickOrderDetail = () => {
    navigate(`/user/orderDetail/${orderId}`);
  };

  return (
    <div className="fixed bottom-0 w-full sm:w-[450px] p-[16px] pb-12 bg-white flex flex-row space-x-[8px]">
      <button
        className="grow border border-[#FF7300] h-[50px] rounded-[8px] text-[16px] text-[#FF7300]"
        onClick={handleClickOrderDetail}
      >
        주문 조회
      </button>
      <button
        className="grow bg-[#FF7300] h-[50px] rounded-[8px] text-[16px] text-white"
        onClick={handleClickHome}
      >
        홈으로 가기
      </button>
    </div>
  );
};

export default OrderAcceptButton;
