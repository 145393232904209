import { ReactNode } from "react";
import Label from "../atoms/label";

interface defaultProps {
  label: string;
  children: ReactNode;
}

const Element = ({ label, children }: defaultProps) => {
  return (
    <div className="flex flex-col space-y-1">
      <Label className="w-full font-bold text-[16px]">{label}</Label>
      {children}
    </div>
  );
};

export default Element;
