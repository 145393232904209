import StateGroup from "../../molecules/statusGroup";
import { useAtomValue } from "jotai";
import { recommendLevelAtom } from "../../../stores/recommend/atoms";
import { recommendLevelComment } from "../../../contents/recommend";

const RecommendStatusBar = () => {
  const recommendLevel = useAtomValue(recommendLevelAtom);

  return (
    <div className="w-full">
      <StateGroup comment={recommendLevelComment} focus={recommendLevel} />
    </div>
  );
};

export default RecommendStatusBar;
