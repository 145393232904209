/// 결제정보 확인 및 구매 동의 체크박스, 체크박스 클릭 시 결제 클릭 가능
import OrderAgreementTotal from "../../molecules/order/orderAgreementTotal";
import Image from "../../atoms/image";
import Label from "../../atoms/label";
import { useAtom, useAtomValue } from "jotai";
import {
  agreementTotalAtom,
  customerEmailAtom,
  customerNameAtom,
  customerPhoneAtom,
  deliveryAddressAtom,
  deliveryAddressDetailAtom,
  deliveryMemoAtom,
  deliveryNameAtom,
  deliveryPhoneAtom,
  deliveryZipCodeAtom,
  paymentAgreeAtom,
} from "../../../stores/order/atoms";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setAddress } from "../../../api/customOrder";

const OrderButton = () => {
  const [params] = useSearchParams();
  const orderId = params.get("orderId");
  const navigate = useNavigate();

  const [accept, setAccept] = useState(false);
  const [paymentAgree, setPaymentAgree] = useAtom(paymentAgreeAtom);

  const customerName = useAtomValue(customerNameAtom);
  const customerEmail = useAtomValue(customerEmailAtom);
  const customerPhone = useAtomValue(customerPhoneAtom);

  const deliveryName = useAtomValue(deliveryNameAtom);
  const deliveryAddress = useAtomValue(deliveryAddressAtom);
  const deliveryAddressDetail = useAtomValue(deliveryAddressDetailAtom);
  const deliveryZipCode = useAtomValue(deliveryZipCodeAtom);
  const deliveryPhone = useAtomValue(deliveryPhoneAtom);
  const deliveryMemo = useAtomValue(deliveryMemoAtom);

  const agreementTotal = useAtomValue(agreementTotalAtom);

  /// 데이터를 전역으로 관리하기 때문에 페이지에 접근했을 때 초기화 진행
  useEffect(() => {
    setPaymentAgree(false);
  }, []);

  useEffect(() => {
    if (
      !paymentAgree ||
      !customerName ||
      !customerEmail ||
      !customerPhone ||
      !deliveryName ||
      !deliveryAddress ||
      !deliveryAddressDetail ||
      !deliveryZipCode ||
      !deliveryPhone ||
      !deliveryMemo ||
      !agreementTotal
    ) {
      setAccept(false);
      return;
    }
    setAccept(true);
  }, [
    customerName,
    customerEmail,
    customerPhone,
    deliveryName,
    deliveryAddress,
    deliveryAddressDetail,
    deliveryZipCode,
    deliveryPhone,
    deliveryMemo,
    agreementTotal,
    paymentAgree,
  ]);

  const handleClick = () => {
    setPaymentAgree(!paymentAgree);
  };

  const handleSubmitClick = async () => {
    if (!accept) {
      alert("필수 항목을 모두 입력해주세요.");
      return;
    }
    if (orderId)
      await setAddress({
        orderId: orderId,
        address: deliveryAddress,
        addressDetail: deliveryAddressDetail,
        zipCode: Number(deliveryZipCode),
        deliveryMessage: deliveryMemo,
        phone: deliveryPhone,
      });
    /// 결제 절차 진행(개발 예정)
    navigate("/order/payments");
  };

  return (
    <div className="py-[32px] flex flex-col">
      <OrderAgreementTotal
        check={paymentAgree}
        handleClick={handleClick}
        label="(필수) 결제정보를 확인하였으며, 구매에 동의합니다."
      />
      <button
        className={`rounded-[8px] h-[56px] flex flex-row justify-center items-center space-x-2 ${
          accept
            ? "bg-gradient-to-r from-[#EB6143] to-[#FF7300] text-white cursor-pointer"
            : "text-black bg-[#DDDDDD] cursor-not-allowed"
        }`}
        onClick={handleSubmitClick}
      >
        <Image src={`svgs/order/pay${accept ? "White" : "Black"}.svg`}></Image>
        <Label className="">지금바로 결제하기</Label>
      </button>
      <div className="flex flex-row justify-center items-center mt-2 space-x-1">
        <Image src="svgs/order/info.svg" />
        <Label className="text-[12px] text-[#EB6143] mt-1">
          50,000원 이상 구매시 무료배송
        </Label>
      </div>
    </div>
  );
};

export default OrderButton;
