import { ReactNode } from "react";
import Layout from "../layout";

interface defaultProps {
  contentHeader: ReactNode;
  navigationBar: ReactNode;
  userInformation: ReactNode;
  userCustomOrderCheck: ReactNode;
  userCouponCheck: ReactNode;
  userQuickMenu: ReactNode;
}

const UserTemplate = ({
  contentHeader,
  navigationBar,
  userInformation,
  userCustomOrderCheck,
  userCouponCheck,
  userQuickMenu,
}: defaultProps) => {
  return (
    <>
      <div className="flex flex-col">
        {contentHeader}
        <div className="p-[16px] space-y-[24px]">
          {userInformation}
          {userCustomOrderCheck}
          {/* {userCouponCheck} */}
          {userQuickMenu}
        </div>
      </div>
      {navigationBar}
    </>
  );
};

export default UserTemplate;
