import { useState } from "react";
import TagLabel from "../atoms/tagLabel";
import Input from "../atoms/input";

interface defaultProps {
  value: Array<string | undefined>;
  setValue: React.Dispatch<React.SetStateAction<(string | undefined)[]>>;
}

const TagGroup = ({ value, setValue }: defaultProps) => {
  const [inputValue, setInputValue] = useState("");
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.nativeEvent.isComposing) return;
    if (event.key === "Enter" && inputValue.trim() !== "") {
      event.stopPropagation();
      setValue([...value, inputValue.trim()]);
      setInputValue("");
    }
  };

  const handleClickLabel = (deleteIndex: number) => {
    const newValue: Array<string | undefined> = [];
    value.map((value, index) => {
      if (index !== deleteIndex) newValue.push(value);
      return 0;
    });

    setValue(newValue);
  };
  return (
    <>
      <div className="flex flex-col justify-center h-[50px] w-full bg-[#D9D9D9] p-4">
        <Input
          value={inputValue}
          handleInputChange={handleInputChange}
          handleKeyDown={handleKeyDown}
          placeholder="이곳에서 입력해주세요."
        />
      </div>
      <div
        className={`flex flex-row flex-wrap justify-start content-start pt-1 mt-5
    ${value.length ? "" : "hidden"}`}
      >
        {value.map((value, index) => {
          return (
            <TagLabel
              key={index}
              index={index}
              handleClickLabel={handleClickLabel}
            >
              {value}
            </TagLabel>
          );
        })}
      </div>
    </>
  );
};

export default TagGroup;
