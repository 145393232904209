import { useEffect, useState } from "react";
import Image from "../../atoms/image";
import Label from "../../atoms/label";

interface defaultProps {
  name: string;
  image: string;
  count: number;
  price: number;
  status: string;
}

const orderStatus = [
  { status: "접수", number: 0, color: "#0080FF" },
  { status: "제조", number: 0, color: "#0080FF" },
  { status: "테스트", number: 0, color: "#FF8A00" },
  { status: "인증", number: 0, color: "#0080FF" },
  { status: "배송", number: 0, color: "#009900" },
  { status: "완료", number: 0, color: "#009900" },
];

const UserOrderProductItem = ({
  name,
  image,
  count,
  price,
  status,
}: defaultProps) => {
  const [color, setColor] = useState("");

  useEffect(() => {
    for (let i = 0; i < orderStatus.length; i++) {
      if (orderStatus[i].status === status) {
        setColor(orderStatus[i].color);
        break;
      }
    }
  }, []);

  return (
    <div className="flex flex-row">
      <div className="w-[80px] h-[80px] border rounded-[8px] overflow-hidden">
        <Image src={image} width={80} />
      </div>
      <div className="grow flex flex-col justify-between pb-1 px-[16px] items-start">
        <div>
          <Label className="text-[16px]">{name}</Label>
          <Label className="text-[12px]">{`주문 수량 : ${count}  | ${price.toLocaleString(
            "KR"
          )}원`}</Label>
        </div>
        <div
          className="text-[11px] text-white px-2 py-1 rounded-[4px]"
          style={{ background: color }}
        >
          {status}
        </div>
      </div>
    </div>
  );
};

export default UserOrderProductItem;
