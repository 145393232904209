import Image from "../atoms/image";

interface defaultProps {
  src: string;
  label: string;
  bg: string;
  color: string;
  handleClick: React.MouseEventHandler<HTMLButtonElement>;
}

const LoginButton = ({ src, label, bg, color, handleClick }: defaultProps) => {
  const bgColor = `#${bg}`;
  const textColor = `#${color}`;
  return (
    <button
      onClick={handleClick}
      className="flex flex-row items-center rounded-2xl w-full h-[70px] px-10"
      style={{ backgroundColor: bgColor, color: textColor }}
    >
      <Image width={30} height={30} src={src} />
      <div className="grow text-center text-[20px]">{label}</div>
    </button>
  );
};

export default LoginButton;
