import Input from "../../atoms/input";

interface defaultProps {
  label: string;
  value: string;
  handleInputChange: React.ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  essential?: boolean;
}

const OrderInputElement = ({
  label,
  value,
  handleInputChange,
  placeholder,
  essential = false,
}: defaultProps) => {
  return (
    <div>
      <div className="text-[14px] text-[#686868] mb-[8px] ml-[8px]">
        {`${label} ${essential && "*"}`}
      </div>
      <div className="border border-[#686868] rounded-[8px] py-[16px] px-[12px]">
        <Input
          value={value}
          placeholder={placeholder}
          handleInputChange={handleInputChange}
          className="placeholder:text-[#686868]"
        />
      </div>
    </div>
  );
};

export default OrderInputElement;
