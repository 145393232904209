import Image from "../../atoms/image";
import Label from "../../atoms/label";

interface defaultProps {
  // brand: string;
  name: string;
  price: number;
  count: number;
  image: string;
}

const OrderProductItem = ({
  // brand,
  name,
  price,
  count,
  image,
}: defaultProps) => {
  return (
    <div className="flex flex-row py-[16px] space-x-[16px]">
      <div className="border w-[30%] max-w-[100px] min-w-[80px] aspect-square overflow-hidden">
        <Image src={image} width={"100%"} />
      </div>

      <div className="flex flex-col justify-between py-1">
        <div className="flex flex-col space-y-1">
          {/* <Label className="text-[14px] text-[#A6A6A6]">{brand}</Label> */}
          <Label className="text-[16px] font-bold">{name}</Label>
        </div>
        <div className="text-[16px] text-[#EB6143]">{`${price.toLocaleString(
          "ko-KR"
        )} / ${count}개`}</div>
      </div>
    </div>
  );
};

export default OrderProductItem;
