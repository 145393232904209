import { useNavigate } from "react-router-dom";

const MainFooter = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col bg-gray-900 p-6 text-white">
      <FooterElement
        title="Company Info"
        contents={[
          {
            label: "대표자명",
            value: "배성준",
          },
          {
            label: "주소",
            value: "(48059)부산광역시 해운대구 센텀동로 45, 1층 101호(우동)",
          },
          {
            label: "이메일",
            value: "help@studioforu.com",
          },
          { label: "사업자등록번호", value: "751-16-02446" },
          { label: "통신판매신고번호", value: "2024-부산해운대-1007" },
        ]}
      />
      <button
        className="w-full text-[#dddddd] text-[14px] text-left font-bold mt-2"
        onClick={() => {
          navigate("/privacyPolicy");
        }}
      >
        개인정보처리방침
      </button>
    </div>
  );
};

const FooterElement = ({
  title,
  contents,
}: {
  title: string;
  contents: { label: string; value: string; url?: string }[];
}) => {
  return (
    <div className="flex flex-col w-full">
      <div className="text-[14px] font-bold pb-2">{title}</div>
      <div className="w-full">
        {contents.map((value) => {
          return (
            <div className="flex flex-row items-start text-[12px] pb-1 pl-2">
              <div className="w-32 min-w-32 font bold">{value.label}</div>
              <div className="grow">{value.value}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MainFooter;
