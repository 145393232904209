import { ReactNode } from "react";
import Layout from "../layout";
import ContentHeader from "../organisms/contentHeader";

interface defaultProps {
  orderList: ReactNode;
}

const OrderListTemplate = ({ orderList }: defaultProps) => {
  return (
    <>
      <ContentHeader>내 주문</ContentHeader>
      <div className="flex flex-col">{orderList}</div>
    </>
  );
};

export default OrderListTemplate;
