import {
  ANONYMOUS,
  loadTossPayments,
  TossPaymentsWidgets,
} from "@tosspayments/tosspayments-sdk";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import {
  customerEmailAtom,
  customerNameAtom,
  customerPhoneAtom,
  orderIdAtom,
  orderProductNameAtom,
  orderProductPriceAtom,
} from "../../../stores/order/atoms";
import { preRequest } from "../../../api/tossPayments";

// 토스페이먼츠에서 발급하는 클라이언트 키로 사업자 등록 후 개별 키를 받아서 사용할 수 있음.
const clientKey = "test_gck_docs_Ovk5rk1EwkEbP0W43n07xlzm";

// 고객을 특정할 수 있는 아이디지만 다른 개인정보를 유추할 수 없는 고유 아이디
const customerKey = "x4EUmdZ_Ads4KdpydoK2G";

// interface TossPaymentsParams {
//   price: number;
//   orderId: string;
//   orderName: string;
//   customerEmail: string;
//   customerName: string;
//   customerMobilePhone: string;
// }

const TossPayments = () => {
  const orderId = useAtomValue(orderIdAtom);
  const orderName = useAtomValue(orderProductNameAtom);
  const customerEmail = useAtomValue(customerEmailAtom);
  const customerName = useAtomValue(customerNameAtom);
  const customerMobilePhone = useAtomValue(customerPhoneAtom);
  const orderPrice = useAtomValue(orderProductPriceAtom);
  const [widgets, setWidgets] = useState<null | TossPaymentsWidgets>(null);
  const [amount, setAmount] = useState<{ currency: string; value: number }>({
    currency: "KRW",
    value: orderPrice,
  });
  const [ready, setReady] = useState<boolean>(false);

  // 주문번호 생성 및 고객 고유 아이디 받아오기
  // useEffect(() => {});

  // 결제위젯 업데이트
  useEffect(() => {
    const fetchPaymentWidgets = async () => {
      // 클라이언트 키로 결제위젯 초기화
      const tossPayments = await loadTossPayments(clientKey);
      // 회원 결제
      const widgets = tossPayments.widgets({
        customerKey: ANONYMOUS,
      });

      // 결제 위젯 저장
      setWidgets(widgets);
    };

    fetchPaymentWidgets();
  }, [clientKey]);
  // }, [clientKey, customerKey]);

  // 결제위젯 UI 렌더링
  useEffect(() => {
    const renderPaymentWidgets = async () => {
      if (widgets == null) {
        return;
      }
      // 결제 금액 설정
      await widgets.setAmount(amount);

      await Promise.all([
        // ------  결제 UI 렌더링 ------
        widgets.renderPaymentMethods({
          selector: "#payment-method",
          variantKey: "DEFAULT",
        }),
        // ------  이용약관 UI 렌더링 ------
        widgets.renderAgreement({
          selector: "#agreement",
          variantKey: "AGREEMENT",
        }),
      ]);

      setReady(true);
    };

    renderPaymentWidgets();
  }, [widgets]);

  // 위젯이 변경되었거나 금액이 변경되었을 때 금액 설정
  // useEffect(() => {
  //   if (widgets == null) {
  //     return;
  //   }

  //   widgets.setAmount(amount);
  // }, [widgets, amount]);

  return (
    <div className="flex flex-col items-center p-[24px] auto w-full">
      <div className="max-w-[540px] w-full">
        {/* 결제 UI */}
        <div id="payment-method" />
        {/* 이용약관 UI */}
        <div id="agreement" />
        {/* 결제하기 버튼 */}
        <div className="btn-wrapper px-[24px] w-full">
          <button
            className="button w-full py-[11px] px-[22px] rounded-lg bg-[#3282f6] text-[#f9fcff] font-semibold text-xl"
            disabled={!ready}
            onClick={async () => {
              preRequest({ orderId: orderId, amount: `${orderPrice}` });
              try {
                // ------ '결제하기' 버튼 누르면 결제창 띄우기 ------
                // 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
                // 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
                if (widgets)
                  await widgets.requestPayment({
                    orderId,
                    orderName,
                    successUrl:
                      window.location.origin + "/order/payments/success",
                    failUrl: window.location.origin + "/order/payments/fail",
                    customerEmail,
                    customerName,
                    customerMobilePhone,
                  });
              } catch (error) {
                // 에러 처리하기
                console.error(error);
              }
            }}
          >
            결제하기
          </button>
        </div>
      </div>
    </div>
  );
};

export default TossPayments;

// btn primary w-100
