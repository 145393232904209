import { useCallback, useEffect, useState } from "react";

interface ScrollDirection {
  direction: "up" | "down";
  isAtTop: boolean;
  lastScrollY: number;
}

const useScrollDirection = () => {
  const [scrollData, setScrollData] = useState<ScrollDirection>({
    direction: "up",
    isAtTop: true,
    lastScrollY: 0, // 초기값에 lastScrollY 추가
  });

  const updateScrollDirection = useCallback(() => {
    const scrollY = window.pageYOffset;
    const isAtTop = scrollY === 0;

    setScrollData((prevScrollData) => {
      const newDirection = scrollY > prevScrollData.lastScrollY ? "down" : "up";
      return {
        direction: newDirection,
        isAtTop,
        lastScrollY: scrollY,
      };
    });
  }, []);

  useEffect(() => {
    let timeoutId: number | null = null;

    const throttledScrollHandler = () => {
      if (timeoutId === null) {
        timeoutId = window.setTimeout(() => {
          updateScrollDirection();
          timeoutId = null;
        }, 200);
      }
    };

    window.addEventListener("scroll", throttledScrollHandler);
    return () => {
      window.removeEventListener("scroll", throttledScrollHandler);
      if (timeoutId) window.clearTimeout(timeoutId);
    };
  }, [updateScrollDirection]);

  // lastScrollY를 제외한 나머지 속성만 반환
  const { lastScrollY, ...returnData } = scrollData;
  return returnData;
};

export default useScrollDirection;
