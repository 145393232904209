import { ReactNode } from "react";

interface defaultProps {
  children: ReactNode;
  className?: string;
}

const Label = ({ children, className }: defaultProps) => {
  return <div className={className + " mt-1"}>{children}</div>;
};

export default Label;
