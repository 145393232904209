import Input from "../../atoms/input";
import Label from "../../atoms/label";

interface defaultProps {
  zipCode: number;
  address: string;
  addressDetail: string;
  handleInputChange: React.ChangeEventHandler<HTMLInputElement>;
  handleButtonClick: () => void;
}

const OrderAddressElement = ({
  zipCode,
  address,
  addressDetail,
  handleInputChange,
  handleButtonClick,
}: defaultProps) => {
  return (
    <div>
      <div className="text-[14px] text-[#686868] mb-[8px] ml-[8px]">주소 *</div>
      <div className="space-y-[8px]">
        <div className="flex flex-row space-x-[8px]">
          <Label className="border border-[#686868] rounded-[8px] grow py-[16px] px-[20px] h-[58px] flex items-center text-[14px] w-full">
            {zipCode === 0 ? "" : zipCode}
          </Label>
          <button
            onClick={handleButtonClick}
            className="w-[180px] text-[16px] text-white bg-[#FF7300] rounded-[8px]"
          >
            주소 검색
          </button>
        </div>
        <Label className="border border-[#686868] rounded-[8px] grow py-[16px] px-[20px] h-[58px] flex items-center text-[14px] w-full">
          {address}
        </Label>
        <div className="border border-[#686868] rounded-[8px] py-[16px] px-[12px]">
          <Input
            value={addressDetail}
            placeholder=""
            handleInputChange={handleInputChange}
            className="placeholder:text-[#686868] text-[14px]"
          />
        </div>
      </div>
    </div>
  );
};

export default OrderAddressElement;
