import { useNavigate } from "react-router-dom";
import Label from "../../atoms/label";
import UserOrderProductItem from "./userOrderProductItem";

interface defaultProps {
  orderData: {
    orderNumber: string;
    product: {
      name: string;
      image: string;
      count: number;
      price: number;
      status: string;
    }[];
  };
  click?: boolean;
}

const UserOrderProductList = ({ orderData, click = true }: defaultProps) => {
  const product = orderData.product;
  const navigate = useNavigate();

  return (
    <div
      className="flex flex-col"
      onClick={() => {
        click && navigate(`/user/orderDetail/${orderData.orderNumber}`);
      }}
    >
      <Label className="text-[#787878] text-[12px] mb-2 truncate">{`주문번호 : ${orderData.orderNumber}`}</Label>
      <div className="flex flex-col space-y-2">
        <UserOrderProductItem
          count={1}
          image={product[0].image}
          name={product[0].name}
          price={80000}
          status={product[0].status}
        />
      </div>
    </div>
  );
};

export default UserOrderProductList;
