const UserCustomOrderCheckButton = () => {
  return (
    // <div className="flex flex-col space-y-[8px]">
    //   <button className="h-[54px] rounded-[8px] text-white bg-[#DDDDDD] w-full pt-1">
    //     리뷰 작성
    //   </button>
    //   <button className="h-[54px] rounded-[8px] text-white bg-[#FF7300] border border-[#E16600] w-full pt-1">
    //     주문 조회하기
    //   </button>
    //   <button className="h-[54px] rounded-[8px] text-white bg-[#0085FF] border border-[#006ACB] w-full pt-1">
    //     구매 확정
    //   </button>
    // </div>
    <div className="flex flex-col space-y-2 mt-6 pb-4 border-b">
      <div className="flex flex-row gap-2  h-14">
        <button className="grow h-full border rounded-[8px]">주문취소</button>
        <button className="grow h-full border rounded-[8px]">
          교환 / 환불
        </button>
      </div>
      <button className="grow  h-14 border rounded-[8px] bg-black text-white">
        구매 확정
      </button>
    </div>
  );
};

export default UserCustomOrderCheckButton;
