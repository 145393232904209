import { useNavigate } from "react-router-dom";
import { getRefreshToken } from "../../../utils/refreshToken";
import Element from "../../molecules/element";

const MainContent = () => {
  const token = getRefreshToken();
  const navigate = useNavigate();
  return (
    <div className="flex flex-col p-[16px] space-y-8">
      <CustomOrderComponent />
      {!token && <LoginComponent />}
      <CustomerReview />
      <CustomOrderComponent1 />
    </div>
  );
};

const LoginComponent = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col p-8 bg-[#4C005A] text-white rounded-[8px] space-y-4">
      <div className="text-[18px] font-bold">
        지금 회원가입 하면
        <br />
        나에게 맞는 향기를 알려드려요!
      </div>
      <button
        className="text-[18px] border border-white rounded-[8px] p-4"
        onClick={() => {
          navigate("/login");
        }}
      >
        회원가입 하고 알아보기
      </button>
    </div>
  );
};

const CustomOrderComponent = () => {
  const token = getRefreshToken();
  const navigate = useNavigate();

  return (
    <div className="relative w-full aspect-square p-8 flex flex-col justify-between">
      <img
        alt=""
        src="images/recommendBackGroundImage.png"
        onClick={() => {
          if (!token) navigate("/login");
          else navigate("/custom-order");
        }}
        className="absolute top-0 left-0 right-0 bottom-0 "
      />
      <div className="flex z-10 text-white text-[20px] font-bold">
        나만의 향기를 만들어보세요.
        <br />
        커스텀 오더 오픈!
      </div>
      <div className="flex flex-col z-10 text-white text-[18px]">
        <div>고객 평점</div>
        <div>
          ★ ★ ★ ★ ★ 5/5 <span className="text-[14px]">(리뷰 323건)</span>
        </div>
      </div>
    </div>
  );
};

const CustomOrderComponent1 = () => {
  const token = getRefreshToken();
  const navigate = useNavigate();

  return (
    <div className="relative w-full aspect-square p-8 flex flex-col justify-between">
      <img
        alt=""
        src="images/recommendBackGroundImage2.png"
        onClick={() => {
          if (!token) navigate("/login");
          else navigate("/recommendPerfume");
        }}
        className="absolute top-0 left-0 right-0 bottom-0 "
      />
      <div className="flex z-10 text-white text-[20px] font-bold">
        AI가 알려주는
        <br />
        나에게 딱 맞는 향이 궁금해?
      </div>
      <div className="flex flex-col z-10 text-white text-[14px] font-bold">
        <div>
          이미 1,000여명의 사람들이 <br />
          자신과 딱 맞는 향을 알아갔어요!
        </div>
      </div>
    </div>
  );
};

const CustomerReview = () => {
  interface reviewType {
    review: string;
    name: string;
    rate: number;
    date: string;
    image: string;
  }

  const reviews: reviewType[] = [
    {
      review: "넘 좋아요!",
      name: "나만의 시그니처 향기",
      rate: 5,
      date: "11/04 21:08",
      image: "/images/review/review1.jpg",
    },
    {
      review: "생각 했던 향이네요",
      name: "일본에서의 추억",
      rate: 5,
      date: "11/12 01:12",
      image: "/images/review/review2.jpg",
    },
    {
      review: "여행지에서 맡은 느낌이에요",
      name: "강릉 앞바다 디퓨저",
      rate: 5,
      date: "11/17 13:55",
      image: "/images/review/review3.jpg",
    },
  ];

  const ReviewItem = ({ review, name, rate, date, image }: reviewType) => {
    return (
      <div className="flex flex-row w-full py-4 justify-between space-x-4">
        <div className="flex flex-col justify-between grow">
          <div className="text-[16px] font-bold text-nowrap overflow-hidden">
            {review}
          </div>
          <div className="text-[14px] truncate">{name}</div>
          <div className="text-[14px]">{"★ ".repeat(rate)}</div>
          <div className="text-[12px] font-bold">{date}</div>
        </div>
        <img
          alt=""
          src={image !== "/" ? image : "/images/defaultImage.png"}
          className="flex h-[86px] w-[86px] ratio-square border"
        ></img>
      </div>
    );
  };

  return (
    <Element label="고객 리뷰">
      <div className="border rounded-[8px] divide-y px-4">
        {reviews.map((value, index) => {
          return <ReviewItem key={index} {...value} />;
        })}
      </div>
    </Element>
  );
};

export default MainContent;
