import UserQuickMenuItem from "./userQuickMenuItem";

interface defaultProps {
  menuList: { label: string; url: string }[];
}

const UserQuickMenuList = ({ menuList }: defaultProps) => {
  return (
    <div className="flex flex-col divide-y ">
      {menuList.map((value, index) => {
        return <UserQuickMenuItem key={index} {...value} />;
      })}
    </div>
  );
};

export default UserQuickMenuList;
