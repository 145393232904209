export const orderAgreementList = [
  {
    label: "주문에 대한 개인정보 수집 이용 동의",
    content: (
      <div>
        회사는 회원가입, 민원 등 고객상담 처리, 본인확인(14세 미만 아동 확인) 등
        의사소통을 위한 정보 활용 및 이벤트 등과 같은 마케팅용도 활용, 회원
        서비스 이용에 대한 통계, 이용자들의 개인 정보를 통한 서비스 개발을 위해
        아래와 같은 개인정보를 수집하고 있습니다.
        <br />
        <br />- 수집항목: 구매자정보(이름, 전화번호, 휴대폰번호, 이메일),
        상품구매/취소/반품/교환/환불 정보, 수령인 정보(이름, 주소, 전화번호,
        휴대폰번호) 비회원 비밀번호
        <br />
        - 수집/이용목적: 서비스 제공 및 계약의 이행, 구매 및 대금결제, 물품배송
        또는 청구지 발송, 불만처리 등 민원처리, 회원관리 등을 위한 목적
        <br />- 이용기간: 개인정보 수집 및 이용목적 달성 시 까지
        <br />
        <br />
        원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체
        없이 파기합니다.
        <br />
        단, 관계법령의 규정에 의하여 보전할 필요가 있는 경우 일정기간 동안
        개인정보를 보관할 수 있습니다.
      </div>
    ),
  },
  {
    label: "이용약관 동의",
    content: (
      <div>
        스튜디오 프래그런스(이하 "회사")는 제품 구매 및 서비스 이용에 대한
        약관을 아래와 같이 규정합니다. 이용자는 본 약관에 동의함으로써 회사의
        서비스를 이용할 수 있습니다.
        <br />
        <br />- 약관의 목적: 본 약관은 회사가 제공하는 제품 및 서비스의 이용
        조건과 절차, 이용자와 회사 간의 권리와 의무를 규정합니다.
        <br />- 약관의 동의: 이용자는 본 약관에 동의함으로써 회사의 제품 및
        서비스를 구매하고 이용할 수 있습니다.
        <br />- 수정 및 공지: 회사는 관련 법령을 위배하지 않는 범위 내에서 본
        약관을 수정할 수 있으며, 변경 시에는 사전 공지합니다.
        <br />- 서비스 제공: 회사는 이용자가 신청한 서비스에 대해 최선을 다해
        제공하며, 서비스 제공에 필요한 정보를 요청할 수 있습니다.
        <br />- 이용자의 의무: 이용자는 본 약관 및 관련 법령을 준수해야 하며,
        타인의 권리를 침해하거나 불법적인 행위를 해서는 안 됩니다.
        <br />- 책임 제한: 회사는 서비스 제공과 관련하여 발생한 손해에 대해 법적
        책임을 지지 않습니다. 단, 고의 또는 중과실로 인한 경우는 제외합니다.
        <br />- 분쟁 해결: 본 약관과 관련하여 발생하는 분쟁은 대한민국 법률을
        따르며, 관할 법원은 회사 소재지의 관할 법원으로 합니다.
        <br />
        <br /> 위 내용을 충분히 이해하고 동의합니다.
      </div>
    ),
  },
];
