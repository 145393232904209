import Element from "../../molecules/element";
import CouponElement from "../../molecules/user/couponElement";

const couponData = {
  coupon: 0,
  orderCount: 0,
};

const UserCouponCheck = () => {
  return (
    <Element label="보유 쿠폰">
      <div className="flex flex-row divide-x">
        <CouponElement
          label="보유 쿠폰"
          count={couponData.coupon}
          countType="장"
        />
        <CouponElement
          label="누적 주문건"
          count={couponData.coupon}
          countType="건"
        />
      </div>
    </Element>
  );
};

export default UserCouponCheck;
