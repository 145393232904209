/**
 * LoadingAnimation 컴포넌트
 *
 * 이 컴포넌트는 API요청이나 렌더링 과정에 콘텐츠가 존재하지 않을 때 나타내는 로딩 애니메이션이 있는 컴포넌트 입니다.
 */

/**
 * LoadingAnimation 컴포넌트
 */
const LoadingAnimation = () => {
  return (
    <div className="flex items-center justify-center">
      <span className="w-3 h-3 rounded-full block mx-auto my-4 relative animate-loader"></span>
    </div>
  );
};

export default LoadingAnimation;

/*
  tailwind.config.js 파일 추가 필요
  
  extend: {
    keyframes: {
      loader: {
        "0%, 100%": {
          boxShadow:
            "14px 0 0 -1px #FF9830, 38px 0 0 -1px #FF7618, -14px 0 0 -1px #FFBA48, -38px 0 0 -1px #FFBA48",
        },
        "20%": {
          boxShadow:
            "14px 0 0 -1px #FFBA48, 38px 0 0 -1px #FF9830, -14px 0 0 -1px #FFBA48, -38px 0 0 2px #FF5500",
        },
        "40%": {
          boxShadow:
            "14px 0 0 -1px #FFBA48, 38px 0 0 -1px #FFBA48, -14px 0 0 2px #FF5500, -38px 0 0 -1px #FF7618",
        },
        "60%": {
          boxShadow:
            "14px 0 0 2px #FF5500, 38px 0 0 -1px #FFBA48, -14px 0 0 -1px #FF7618, -38px 0 0 -1px #FF9830",
        },
        "80%": {
          boxShadow:
            "14px 0 0 -1px #FF7618, 38px 0 0 2px #FF5500, -14px 0 0 -1px #FF9830, -38px 0 0 -1px #FFBA48",
        },
      },
    },
    animation: {
      loader: "loader 1.5s linear infinite",
    },
  },
*/
