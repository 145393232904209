import { useAtomValue } from "jotai";
import {
  appearanceAtom,
  fragranceTasteAtom,
  imageAtom,
  perfumeProductAtom,
  recommendTitleAtom,
  seasonalCheckedAtom,
  storyAtom,
} from "../../stores/recommend/atoms";
import { seasonal } from "../../contents/recommend";
import { customOrderRegist } from "../../api/customOrder";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { userNameAtom } from "../../stores/user/atoms";
import LoadingAnimation from "../utils/loadingAnimation";

const RecommendLoading = () => {
  const navigate = useNavigate();
  const userName = useAtomValue(userNameAtom);
  const title = useAtomValue(recommendTitleAtom);
  const perfumeProduct = useAtomValue(perfumeProductAtom);
  const seasonalChecked = useAtomValue(seasonalCheckedAtom);
  const fragranceTaste = useAtomValue(fragranceTasteAtom);
  const appearance = useAtomValue(appearanceAtom);
  const story = useAtomValue(storyAtom);
  const image = useAtomValue(imageAtom);

  const api = async () => {
    const seasonal_taste: Array<string | undefined> = [];

    seasonalChecked.map((value, index) => {
      if (value) seasonal_taste.push(seasonal[index]);
      return 0;
    });

    const data = await customOrderRegist({
      title,
      pre_used_product: perfumeProduct,
      fragrance_taste: fragranceTaste,
      appearance_expression: appearance,
      seasonal_taste,
      story,
    });

    console.log(data);
    navigate(
      `/order?orderId=${data.order_id}&amount=${data.amount}&title=${data.title}`
    );
  };

  useEffect(() => {
    api();
  }, []);
  return (
    <div className="w-full p-[18px]">
      <AnimatePresence mode="wait">
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -30 }}
          transition={{
            duration: 0.5,
            delay: 0.4,
            ease: "easeInOut",
          }}
        >
          <div className="w-full space-y-6 flex flex-col">
            <div className="text-[24px] font-bold flex">
              AI가
              <br />
              {userName}님의 취향을
              <br />
              분석하고 있어요.
            </div>
            {/* <img src="/images/recommend/search.png" /> */}
            <div className="flex pt-40 justify-center grow">
              <LoadingAnimation />
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default RecommendLoading;
