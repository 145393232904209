import ContentHeader from "../organisms/contentHeader";
import OrderAddressInput from "../organisms/order/orderAddressInput";
import OrderAgreementList from "../organisms/order/orderAgreementList";
import OrderAmountInfo from "../organisms/order/orderAmountInfo";
import OrderAmountTotal from "../organisms/order/orderAmountTotal";
import OrderButton from "../organisms/order/orderButton";
import OrderCustomerInput from "../organisms/order/orderCustomerInput";
import OrderPaymentsList from "../organisms/order/orderPaymentsList";
import OrderProductList from "../organisms/order/orderProductList";

import OrderTemplate from "../templates/orderTemplate";

const Order = () => {
  return (
    <OrderTemplate
      contentHeader={<ContentHeader home={true}>결제하기</ContentHeader>}
      productList={<OrderProductList />}
      amountInfo={<OrderAmountInfo />}
      agreementList={<OrderAgreementList />}
      customerInput={<OrderCustomerInput />}
      addressInput={<OrderAddressInput />}
      amountTotal={<OrderAmountTotal />}
      paymentsList={<OrderPaymentsList />}
      orderButton={<OrderButton />}
    ></OrderTemplate>
  );
};

export default Order;
