import ContentHeader from "../organisms/contentHeader";
import NavigationBar from "../organisms/navigationBar";
import UserCouponCheck from "../organisms/user/userCouponCheck";
import UserCustomOrderCheck from "../organisms/user/userCustomOrderCheck";
import UserInformation from "../organisms/user/userInformation";
import UserQuickMenu from "../organisms/user/userQuickMenu";
import UserTemplate from "../templates/userTemplate";

const User = () => {
  return (
    <UserTemplate
      contentHeader={<ContentHeader home={true}>마이페이지</ContentHeader>}
      navigationBar={<NavigationBar />}
      userInformation={<UserInformation />}
      userCustomOrderCheck={<UserCustomOrderCheck />}
      userCouponCheck={<UserCouponCheck />}
      userQuickMenu={<UserQuickMenu />}
    />
  );
};

export default User;
