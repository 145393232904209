import Image from "../../atoms/image";

interface defaultProps {
  src: string;
  focus: boolean;
}

const OrderPaymentsItem = ({ src, focus }: defaultProps) => {
  return (
    <div
      className={`border flex items-center justify-center h-[62px] rounded-[8px] ${
        focus && "border-black"
      }`}
    >
      <Image src={src} width={120}></Image>
    </div>
  );
};

export default OrderPaymentsItem;
