import { ReactNode } from "react";
import Layout from "../layout";

interface defaultProps {
  contentHeader: ReactNode;
  productList: ReactNode;
  amountInfo: ReactNode;
  agreementList: ReactNode;
  customerInput: ReactNode;
  addressInput: ReactNode;
  amountTotal: ReactNode;
  paymentsList: ReactNode;
  orderButton: ReactNode;
}

const OrderTemplate = ({
  contentHeader,
  productList,
  amountInfo,
  agreementList,
  customerInput,
  addressInput,
  amountTotal,
  paymentsList,
  orderButton,
}: defaultProps) => {
  return (
    <>
      <div className="flex flex-col">
        {contentHeader}
        <div className="p-[16px] space-y-[16px]">
          {productList}
          {amountInfo}
          {agreementList}
          {customerInput}
          {addressInput}
          {amountTotal}
          {paymentsList}
          {orderButton}
        </div>
      </div>
    </>
  );
};

export default OrderTemplate;
