import { useNavigate } from "react-router-dom";
import Image from "../../atoms/image";
import Label from "../../atoms/label";

interface defaultProps {
  userImage: string;
  userName: string;
}

const UserProfile = ({ userImage, userName }: defaultProps) => {
  // const navigate = useNavigate();

  // const handleClick = () => {
  //   navigate("/user/edit");
  // };

  return (
    <div className="flex flex-row justify-between items-center border border-[#E2E2E2] rounded-[8px] p-[16px]">
      <div className="flex flex-row items-center space-x-4">
        <div className="relative w-[84px] h-[84px]">
          <div className="w-full h-full border rounded-full overflow-hidden">
            <Image src={userImage} width={84} />
          </div>
          {/* <button className="absolute bottom-0 right-0 w-[32px] h-[32px] border border-[#E2E2E2] rounded-full bg-white flex items-center justify-center">
            <Image src="svgs/user/insertImage.svg" />
          </button> */}
        </div>
        <Label className="text-[20px]">{userName}</Label>
      </div>
      {/* <button
        className="border border-[#E2E2E2] pt-1 pb-[2px] px-2 rounded-[4px] text-[12px] text-[#1F1F1F]"
        onClick={handleClick}
      >
        프로필 수정
      </button> */}
    </div>
  );
};

export default UserProfile;
