import { ReactNode } from "react";

interface defaultProps {
  index: number;
  state: boolean;
  handleClick: (eventIndex: number) => void;
  children: ReactNode;
}
const CheckBoxButton = ({
  index,
  state,
  handleClick,
  children,
}: defaultProps) => {
  const image = ["spring", "summer", "fall", "winter"];
  return (
    <button
      onClick={(event) => {
        event.stopPropagation();
        handleClick(index);
      }}
      className={`flex flex-col justify-between items-center border w-[48%] pt-6 pb-2 px-6 mb-6 ${
        state ? "bg-black text-white" : "bg-[#D9D9D9] text-black"
      }`}
    >
      <img
        src={`/images/recommend/${image[index]}.jpg`}
        className="rounded-full w-full aspect-square"
        alt=""
      ></img>
      <div className="text-[18px] font-bold my-6">{children}</div>
    </button>
  );
};

export default CheckBoxButton;
