import { useEffect, useRef, useState } from "react";
import Label from "../../atoms/label";
import html2canvas from "html2canvas";
import { customOrderInfo } from "../../../api/customOrder";
import { useSearchParams } from "react-router-dom";

interface orderListType {
  image: string;
  order_id: string;
  status: string;
  title: string;
}

const OrderAcceptReceipt = () => {
  const [params] = useSearchParams();
  const orderId = params.get("orderId");
  const [orderData, setOrderData] = useState<orderListType>();

  const getOrderInfo = async () => {
    if (orderId) {
      const data: orderListType = await customOrderInfo({
        orderId: orderId.toString(),
      });
      console.log(data);
      setOrderData(data);
    }
  };

  const captureRef = useRef<HTMLDivElement>(null);

  const handleCapture = async () => {
    if (captureRef.current) {
      try {
        const canvas = await html2canvas(captureRef.current);
        const image = canvas.toDataURL("image/png");

        // 이미지 다운로드
        const link = document.createElement("a");
        link.href = image;
        link.download = "capture.png";
        link.click();
      } catch (error) {
        console.error("캡처 중 오류 발생:", error);
      }
    }
  };

  useEffect(() => {
    getOrderInfo();
  }, []);

  return (
    <div className="p-[16px] pb-[130px]">
      <div className="border rounded-[8px] flex flex-col bg-white">
        <div ref={captureRef} className="pt-[16px] px-[16px]">
          <Label className="text-center mb-[24px]">영수증</Label>
          <div className="space-y-[10px] pb-[20px] border-b">
            <OrderAcceptReceiptItem
              label="금액"
              value={`${(80000).toLocaleString("ko-KR")} 원`}
            />
            <OrderAcceptReceiptItem
              label="주문번호"
              value={orderId ? orderId : ""}
            />
            <OrderAcceptReceiptItem
              label="주문제품"
              value={orderData ? orderData.title : ""}
            />
            <OrderAcceptReceiptItem label="주문수량" value={`총 1개`} />
            <OrderAcceptReceiptItem
              label="결제상태"
              value={orderData ? orderData.status : ""}
            />
          </div>
        </div>
        <div className="grow text-right p-[16px]">
          <button
            onClick={handleCapture}
            className="border border-black rounded-[8px] py-[16px] px-[20px] text-[12px]"
          >
            이미지로 저장
          </button>
        </div>
      </div>
    </div>
  );
};

interface defaultProps {
  label: string;
  value: string;
}

const OrderAcceptReceiptItem = ({ label, value }: defaultProps) => {
  return (
    <div className="flex flex-row">
      <Label className="w-[130px] text-[#B9BABC] text-[12px]">{label}</Label>
      <Label className="grow text-black text-[12px]">{value}</Label>
    </div>
  );
};

export default OrderAcceptReceipt;
