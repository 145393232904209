import Button from "../atoms/button";
import Image from "../atoms/image";
import { useNavigate } from "react-router-dom";
// import HeaderSearchButton from "../molecules/headerSearchButton";

const MainHeader = () => {
  const navigate = useNavigate();
  return (
    <header className="flex flex-row h-[80px] px-5 items-center space-x-[8px] border-b border-studio-orange">
      <div className="h-full w-[60px] flex items-center">
        <img src="/images/mainLogo.png" />
      </div>
      {/* <Button
        className="w-[48px] h-[48px] flex justify-center items-center border rounded-[8px] border-[#E7E7E7]"
        handleClick={() => {
          alert("menu");
        }}
      >
        <Image src="/svgs/list.svg" width={24} height={24} />
      </Button> */}
      <div className="grow flex justify-center items-center text-[18px]">
        {/* <HeaderSearchButton /> */}
      </div>
      <Button
        className="w-[48px] h-[48px] flex justify-center items-center border rounded-[8px] border-[#E7E7E7]"
        handleClick={() => {
          // navigate("/bell");
        }}
      >
        <Image src="/svgs/bell.svg" width={24} height={24} />
      </Button>
      <Button
        className="w-[48px] h-[48px] flex justify-center items-center border rounded-[8px] border-[#E7E7E7]"
        handleClick={() => {
          navigate("/cart");
        }}
      >
        <Image src="/svgs/cart.svg" width={24} height={24} />
      </Button>
    </header>
  );
};

export default MainHeader;
