import { useNavigate } from "react-router-dom";

interface defaultProps {
  label: string;
  url: string;
}

const UserQuickMenuItem = ({ label, url }: defaultProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(url);
  };
  return (
    <button
      className="w-full text-[#494949] text-[16px] text-left p-[16px]"
      onClick={handleClick}
    >
      {label}
    </button>
  );
};

export default UserQuickMenuItem;
