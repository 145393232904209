import Image from "../../atoms/image";
import Label from "../../atoms/label";

interface defaultProps {
  src?: string;
  label: string;
}

const OrderElementLabel = ({ src, label }: defaultProps) => {
  return (
    <div className="flex flex-row items-center space-x-2">
      {src && (
        <div className="w-[24px] h-[24px] flex items-center justify-center">
          <Image src={src} width={16}></Image>
        </div>
      )}
      <Label className="font-bold text-[16px] mt-1">{label}</Label>
    </div>
  );
};

export default OrderElementLabel;
