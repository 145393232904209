import { ApiMiddleware } from "../middleware/middleware";
import { ApiResponseType } from "../types/api";

export const perfumeRecommendRegistApi = async ({
  gender,
  query,
}: {
  gender: string;
  query: string;
}) => {
  const url = `${process.env.REACT_APP_API_URL}/analysis/claude/v2`;
  const data = {
    gender,
    query,
  };
  const response: ApiResponseType | undefined = await ApiMiddleware({
    payload: {
      url,
      method: "POST",
      data,
    },
  });

  if (response && response.status) {
    if (response.status >= 200 && response.status < 300 && response.data) {
      return { success: true, data: response.data };
    } else if (response.status === 401) {
      return { success: false, login: false };
    } else {
      return { success: false };
    }
  }
  return { success: false };
};

export const perfumeRecommendListApi = async (id: number) => {
  const url = `${process.env.REACT_APP_API_URL}/analysis/claude/list`;
  const params = `id=${id}`;
  const response: ApiResponseType | undefined = await ApiMiddleware({
    payload: {
      url: `${url}?${params}`,
      method: "GET",
    },
  });
  if (response && response.status) {
    if (response.status < 400 && response.data) {
      return response.data;
    } else if (response.status === 401) return { login: false };
  }
  return {};
};
