import axios from "axios";
import { refreshAccessToken } from "../api/auth";
import { getAccessToken } from "../utils/accessToken";
import { ApiRequestType, ApiResponseType } from "../types/api";

export const ApiMiddleware = async ({
  payload: { url, method, data, headers },
}: ApiRequestType): Promise<ApiResponseType | undefined> => {
  let refresh: boolean = false;
  /// 인증 인가 모듈
  const authorization = async (): Promise<boolean> => {
    try {
      const state = await refreshAccessToken();
      refresh = true;

      return state;
    } catch (error) {
      console.error(`[Middleware]Authorization Error : ${error}`);
    }
    return false;
  };

  /// API 통신 모듈
  const requestApi = async (
    accessToken: string
  ): Promise<ApiResponseType | undefined> => {
    try {
      const response = await axios({
        url,
        method,
        data,
        headers: {
          ...headers,
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const result: ApiResponseType = {
        data: response.data,
        status: response.status,
      };

      return result;
    } catch (error) {
      // accessToken이 만료되었을 경우 refreshToken을 사용해 토큰을 재발급
      if (
        axios.isAxiosError(error) &&
        error.response?.status === 401 &&
        !refresh
      ) {
        const state = await authorization();
        if (!state) {
          const result: ApiResponseType = {
            status: 401,
          };

          return result;
        }
        const newAccessToken = getAccessToken();
        if (newAccessToken) await requestApi(newAccessToken);
      } else {
        console.error(`[Middleware]Request Error : ${error}`);
        const result: ApiResponseType = {};

        return result;
      }
    }
  };

  let accessToken = getAccessToken();

  if (!accessToken) {
    const state = await authorization();
    if (!state) {
      const result: ApiResponseType = {
        status: 401,
      };

      return result;
    }
    accessToken = getAccessToken();
  }
  if (accessToken) return await requestApi(accessToken);
  return;
};
