import OrderElementLabel from "../../molecules/order/orderElementLabel";
import OrderPaymentsItem from "../../molecules/order/orderPaymentsItem";

const OrderPaymentsList = () => {
  return (
    <div className="flex flex-col pt-[32px]">
      <OrderElementLabel label="결제 수단" src="/svgs/order/pay.svg" />
      <div className="pt-[16px] space-y-[16px] border-t mt-[16px] flex flex-col">
        <OrderPaymentsItem src="images/order/tosspay.png" focus={true} />
      </div>
    </div>
  );
};

export default OrderPaymentsList;
