import OrderAcceptButton from "../organisms/orderAccept/orderAcceptButton";
import OrderAcceptGraphics from "../organisms/orderAccept/orderAcceptGraphics";
import OrderAcceptReceipt from "../organisms/orderAccept/orderAcceptReceipt";
import OrderAcceptTemplate from "../templates/orderAcceptTemplate";

const OrderAccept = () => {
  return (
    <OrderAcceptTemplate
      orderAcceptGraphics={<OrderAcceptGraphics />}
      orderAcceptReceipt={<OrderAcceptReceipt />}
      orderAcceptButton={<OrderAcceptButton />}
    />
  );
};

export default OrderAccept;
