import Image from "../../atoms/image";

interface defaultProps {
  check: boolean;
  label: string;
  handleClick: () => void;
}

const OrderAgreementTotal = ({ check, label, handleClick }: defaultProps) => {
  return (
    <div className="flex flex-col py-[16px]">
      <div
        className={`flex flex-row justify-between p-[16px] rounded-[8px] ${
          check
            ? "bg-gradient-to-r from-[#FF5601] to-[#FFA501] text-white"
            : "bg-[#FFF3E5] text-black"
        }`}
      >
        <div className="flex flex-row space-x-[4px] items-center">
          <button
            onClick={handleClick}
            className="relative w-[30px] h-[30px] flex items-center justify-center"
          >
            {check ? (
              <Image src="/svgs/order/checkBoxWhite.svg" width={21} />
            ) : (
              <Image src="/svgs/order/checkBoxDefault.svg" width={21} />
            )}
          </button>
          <div className="text-[14px] mt-1">{label}</div>
        </div>
      </div>
    </div>
  );
};

export default OrderAgreementTotal;
