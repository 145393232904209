export const orderProductList = [
  {
    brand: "조말론",
    name: "우드세이지 씨쏠트",
    price: 99900,
    count: 1,
    image: "/images/order/sampleImage.png",
  },
  {
    brand: "플로럴",
    name: "비밀정원",
    price: 39900,
    count: 1,
    image: "/images/order/sampleImage2.png",
  },
];

export const orderSalePrice = 0;
export const orderDeliveryPrice = 0;
