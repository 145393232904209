import StateBar from "../atoms/statusBar";

interface defaultProps {
  comment: Array<string>;
  focus: number;
}

const StateGroup = ({ comment, focus }: defaultProps) => {
  return (
    <div className="flex flex-row w-full space-x-1 p-5">
      {comment.map((value, index) => {
        return (
          <div
            key={index}
            className={`flex flex-col space-y-1 ${
              focus === index ? "grow" : "w-[9%]"
            }`}
          >
            <StateBar focus={focus === index} index={index} />
            {focus === index && (
              <div className="text-[10px] text-clip">{value}</div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default StateGroup;
