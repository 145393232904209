import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { confirmPayment } from "../../../api/tossPayments";

const TossPaymentsSuccess = () => {
  const navigate = useNavigate();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [searchParams] = useSearchParams();
  const paymentKey = searchParams.get("paymentKey");
  const orderId = searchParams.get("orderId");
  const amount = searchParams.get("amount");
  const [error, setError] = useState<string | null>(null);

  const handleConfirmPayment = () => {
    navigate("/orderAccept?orderId=" + orderId);
  };

  useEffect(() => {
    setTimeout(() => {
      handleConfirmPayment();
    }, 2000);
  }, [paymentKey, orderId, amount]);

  if (error) {
    return <div className="text-red-500 text-center mt-8">{error}</div>;
  }

  return (
    <div className="flex flex-col items-center p-[24px] auto w-full">
      <div className="flex flex-col items-center confirm-loading w-full max-w-[540px]">
        <div className="flex flex-col items-center">
          <img
            src="https://static.toss.im/lotties/loading-spot-apng.png"
            width="120"
            height="120"
            alt=""
          />
          <h2 className="mt-[32px] mb-0 text-[#0e4dac] font-bold text-[24px] text-center">
            결제 승인중이에요.
          </h2>
        </div>
      </div>
    </div>
  );
};

export default TossPaymentsSuccess;
