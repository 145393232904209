import { ReactNode } from "react";

interface defaultProps {
  children: ReactNode;
  handleClick: React.MouseEventHandler<HTMLButtonElement>;
  className: string;
}

const Button = ({ children, handleClick, className }: defaultProps) => {
  return (
    <button onClick={handleClick} className={className}>
      {children}
    </button>
  );
};

export default Button;
