import { ReactNode } from "react";
import Layout from "../layout";
import ContentHeader from "../organisms/contentHeader";

interface defaultProps {
  orderInformation: ReactNode;
}

const OrderDetailTemplate = ({ orderInformation }: defaultProps) => {
  return (
    <>
      <ContentHeader>주문 내역</ContentHeader>
      <div className="flex flex-col">{orderInformation}</div>
    </>
  );
};

export default OrderDetailTemplate;
