import { Suspense } from "react";
import { getUserInfo } from "../../../api/customOrder";
import Element from "../../molecules/element";
import UserProfile from "../../molecules/user/userProfile";
import { useAtom } from "jotai";
import { userImageAtom, userNameAtom } from "../../../stores/user/atoms";
import LoadingAnimation from "../../utils/loadingAnimation";

// 사용자 데이터 타입 정의
interface UserData {
  name: string;
  nickname: string;
  profile_image_url: string;
}

// 캐시를 위한 객체
let userDataCache: UserData | null = null;
let promise: Promise<void> | null = null;

const fetchUserData = (): UserData => {
  if (userDataCache) return userDataCache;

  if (!promise) {
    promise = getUserInfo()
      .then((data: UserData) => {
        userDataCache = data;
        promise = null;
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        promise = null;
        throw error;
      });
  }

  throw promise;
};

const UserProfileComponent = () => {
  const [userName, setUserName] = useAtom(userNameAtom);
  const [userImage, setUserImage] = useAtom(userImageAtom);

  const data = fetchUserData();

  if (!userName || !userImage) {
    setUserName(data.nickname !== null ? data.nickname : data.name);
    setUserImage(data.profile_image_url);
  }

  return (
    <UserProfile
      userImage={
        userImage ||
        "https://storage.studioforu.com/uploads/kakao_Default_Image_9595769bb6.png"
      }
      userName={userName || ""}
    />
  );
};

const UserLoading = () => {
  return (
    <div className="flex flex-row justify-between items-center border border-[#E2E2E2] rounded-[8px] p-[16px]">
      <div className="flex flex-row items-center justify-center w-full h-[84px]">
        <LoadingAnimation />
      </div>
    </div>
  );
};

const UserInformation = () => {
  return (
    <Element label="내 정보">
      <Suspense fallback={<UserLoading />}>
        <UserProfileComponent />
      </Suspense>
    </Element>
  );
};

export default UserInformation;
