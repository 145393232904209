import { ReactNode } from "react";
import Image from "../../atoms/image";

interface defaultProps {
  check: boolean;
  label: string;
  content: ReactNode;
  open: boolean;
  index: number;
  handleClick: (click: string, indexNum: number) => void;
}

const OrderAgreementItem = ({
  check,
  label,
  content,
  open,
  index,
  handleClick,
}: defaultProps) => {
  return (
    <div className="flex flex-col ">
      <div className="flex flex-row justify-between p-[16px]">
        <div className="flex flex-row space-x-[4px] items-center">
          <button
            onClick={() => {
              handleClick("check", index);
            }}
            className="relative w-[30px] h-[30px] flex items-center justify-center"
          >
            {check ? (
              <Image src="/svgs/order/checkBoxBlack.svg" width={21} />
            ) : (
              <Image src="/svgs/order/checkBoxDefault.svg" width={21} />
            )}
          </button>
          <div className="text-[14px] mt-1">{label}</div>
        </div>
        <button
          onClick={() => {
            handleClick("open", index);
          }}
          className={`w-[30px] h-[30px] flex justify-center items-center   ${
            open ? "animate-rotate45" : "animate-rotate0"
          }`}
        >
          <Image src="svgs/order/plus.svg" />
        </button>
      </div>
      <div
        className={`text-[14px] text-[#686868] p-[20px] tracking-tight border-t ${
          !open && "hidden"
        }`}
      >
        {content}
      </div>
    </div>
  );
};

export default OrderAgreementItem;
