import { ReactNode } from "react";
import Layout from "../layout";

interface defaultProps {
  orderAcceptGraphics: ReactNode;
  orderAcceptReceipt: ReactNode;
  orderAcceptButton: ReactNode;
}

const OrderAcceptTemplate = ({
  orderAcceptGraphics,
  orderAcceptReceipt,
  orderAcceptButton,
}: defaultProps) => {
  return (
    <>
      <div className="flex flex-col space-y-[40px]">
        {orderAcceptGraphics}
        {orderAcceptReceipt}
      </div>
      {orderAcceptButton}
    </>
  );
};

export default OrderAcceptTemplate;
