import { customOrderList } from "../../api/customOrder";
import { useEffect, useState } from "react";
import UserOrderProductList from "../molecules/user/userOrderProductList";

export interface orderListType {
  image: string;
  order_id: string;
  status: string;
  title: string;
}

const OrderList = () => {
  const [orders, setOrders] = useState<Array<orderListType> | undefined>();
  const [currentPage, setCurrentPage] = useState(0);
  const ordersPerPage = 3;

  const getOrderList = async () => {
    const data = await customOrderList();
    if (!data || data.data === null) return;
    const sortedData = [...data].reverse();
    setOrders(sortedData);
  };

  useEffect(() => {
    getOrderList();
  }, []);

  // if (!orders) return null;

  // 주문 내역이 없는 경우 메시지 표시
  if (!orders || orders.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-[400px] p-4">
        <p className="text-gray-500 text-lg font-medium">
          주문 내역이 없습니다
        </p>
      </div>
    );
  }

  const startIndex = currentPage * ordersPerPage;
  const currentOrders = orders.slice(startIndex, startIndex + ordersPerPage);

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleNextPage = () => {
    if (startIndex + ordersPerPage < orders.length) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  return (
    <div className="p-4">
      <div className="space-y-4">
        {currentOrders.map((value) => (
          <div key={value.order_id}>
            <UserOrderProductList
              orderData={{
                orderNumber: value.order_id,
                product: [
                  {
                    name: value.title,
                    image: "/images/defaultDiffuserImage.jpeg",
                    count: 1,
                    price: 80000,
                    status: value.status,
                  },
                ],
              }}
            />
            <div className="flex flex-col space-y-2 mt-6 pb-4 border-b">
              <div className="flex flex-row gap-2 h-14">
                <button className="grow h-full border rounded-[8px]">
                  주문취소
                </button>
                <button className="grow h-full border rounded-[8px]">
                  교환 / 환불
                </button>
              </div>
              <button className="grow h-14 border rounded-[8px] bg-black text-white">
                구매 확정
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* 페이지네이션 버튼 */}
      <div className="flex justify-center gap-4 mt-6">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 0}
          className={`px-4 py-2 rounded-[8px] ${
            currentPage === 0 ? "bg-gray-200" : "bg-black text-white"
          }`}
        >
          이전
        </button>
        <button
          onClick={handleNextPage}
          disabled={startIndex + ordersPerPage >= (orders?.length || 0)}
          className={`px-4 py-2 rounded-[8px] ${
            startIndex + ordersPerPage >= (orders?.length || 0)
              ? "bg-gray-200"
              : "bg-black text-white"
          }`}
        >
          다음
        </button>
      </div>
    </div>
  );
};

export default OrderList;
