import axios, { AxiosResponse } from "axios";
import { AuthResponse } from "../types/auth";
import { getRefreshToken, setRefreshToken } from "../utils/refreshToken";
import { setAccessToken } from "../utils/accessToken";

/**
 * 카카오 로그인을 통해 AccessToken과 RefreshToken을 발급 받는 함수
 * @param code 카카오 로그인을 통해 발급받은 파라미터에 있는 인증 코드
 */
export const authKakao = async (code: string): Promise<boolean> => {
  // const dispatch = useDispatch();
  const url = `${process.env.REACT_APP_API_URL}`;
  const params = `code=${code}`;

  if (url && params) {
    try {
      const response: AxiosResponse<AuthResponse> =
        await axios.get<AuthResponse>(`${url}/auth/kakao?${params}`);
      const data: AuthResponse = response.data;
      const { access_token, refresh_token } = { ...data };

      setRefreshToken(refresh_token);
      setAccessToken(access_token);

      return true;
    } catch (error) {
      console.error(`Kakao Login Error : ${error}`);
    }
  }

  return false;
};

/**
 * AccessToken이 만료되었을 때 RefreshToken을 사용하여 AccessToken을 재발급 받는 함수
 * @returns `true` AccessToken 재발급 완료
 * @returns `false` AccessToken 재발급 실패
 */
export const refreshAccessToken = async (): Promise<boolean> => {
  const refresh_token = getRefreshToken();
  if (!refresh_token) {
    return false;
  }
  const url = `${process.env.REACT_APP_API_URL}`;
  const params = `token=${refresh_token}`;

  if (url && params) {
    try {
      const response: AxiosResponse<AuthResponse> =
        await axios.get<AuthResponse>(`${url}/auth/token/refresh?${params}`);
      const data: AuthResponse = response.data;
      const { access_token } = { ...data };

      setAccessToken(access_token);

      return true;
    } catch (error) {
      console.error(`Refresh Access Token Error : ${error}`);
      return false;
    }
  }
  return false;
};
