import { ApiMiddleware } from "../middleware/middleware";
import { ApiResponseType } from "../types/api";

interface orderBodyParams {
  title: string;
  pre_used_product:
    | Array<{
        name: string;
        brand: string;
      }>
    | undefined;
  fragrance_taste: Array<string | undefined>;
  appearance_expression: Array<string | undefined>;
  seasonal_taste: Array<string | undefined>;
  story: string | undefined;
}

interface reviewBodyType {
  order_id: number;
  total_rate: number;
  accord_rate: Array<{ name: string; rate: number }>;
  season_rate: string;
  time_rate: string;
  endurance_rate: number;
  comment: string;
}

/// 커스텀 오더 주문 접수
export const customOrderRegist = async ({
  title,
  pre_used_product,
  fragrance_taste,
  appearance_expression,
  seasonal_taste,
  story,
}: orderBodyParams) => {
  const url = `${process.env.REACT_APP_API_URL}/order-status/custom-order/regist`;
  const data = {
    title,
    pre_used_product,
    fragrance_taste,
    appearance_expression,
    seasonal_taste,
    story,
  };
  const response: ApiResponseType | undefined = await ApiMiddleware({
    payload: {
      url,
      method: "POST",
      data,
    },
  });
  if (response && response.status) {
    if (response.status < 400 && response.data) {
      return response.data;
    } else if (response.status === 401) return { login: false };
  }
  return {};
};

export const customOrderList = async () => {
  const url = `${process.env.REACT_APP_API_URL}/order-status/custom-order/list`;
  const response: ApiResponseType | undefined = await ApiMiddleware({
    payload: {
      url,
      method: "GET",
    },
  });
  if (response && response.status) {
    if (response.status < 400 && response.data) {
      return response.data;
    } else if (response.status === 401) return { login: false, data: [] };
  }
  return { data: null };
};

export const customOrderInfo = async ({ orderId }: { orderId: string }) => {
  const url = `${process.env.REACT_APP_API_URL}/order-status/custom-order/info`;
  const params = `order_id=${orderId}`;
  const response: ApiResponseType | undefined = await ApiMiddleware({
    payload: {
      url: `${url}?${params}`,
      method: "GET",
    },
  });
  if (response && response.status) {
    if (response.status < 400 && response.data) {
      return response.data;
    } else if (response.status === 401) return { login: false };
  }
  return {};
};

// 시향지 피드백에 대한 API
// data에 대한 정의가 정확하지 않아 테스트 불가
export const customOrderTesting = async ({ orderId }: { orderId: number }) => {
  const url = `${process.env.REACT_APP_API_URL}/order-status/custom-order/testing`;
  const data = {
    order_id: orderId,
  };
  const response: ApiResponseType | undefined = await ApiMiddleware({
    payload: {
      url: `${url}`,
      method: "POST",
      data,
    },
  });
  if (response && response.status) {
    if (response.status < 400 && response.data) {
      return response.data;
    } else if (response.status === 401) return { login: false };
  }
  return {};
};

// 리뷰에 대한 API
// 명세서 업데이트 후 다시 작업
export const customOrderReview = async ({
  order_id,
  total_rate,
  accord_rate,
  season_rate,
  time_rate,
  endurance_rate,
  comment,
}: reviewBodyType) => {
  const url = `${process.env.REACT_APP_API_URL}/order-status/custom-order/testing`;
  const data = {
    order_id,
    total_rate,
    accord_rate,
    season_rate,
    time_rate,
    endurance_rate,
    comment,
  };
  const response: ApiResponseType | undefined = await ApiMiddleware({
    payload: {
      url: `${url}`,
      method: "POST",
      data,
    },
  });
  if (response && response.status) {
    if (response.status < 400 && response.data) {
      return response.data;
    } else if (response.status === 401) return { login: false };
  }
  return {};
};

export const getUserInfo = async () => {
  const url = `${process.env.REACT_APP_API_URL}/order-status/user/info`;
  const response: ApiResponseType | undefined = await ApiMiddleware({
    payload: {
      url: `${url}`,
      method: "GET",
    },
  });
  if (response && response.status) {
    if (response.status < 400 && response.data) {
      return response.data;
    } else if (response.status === 401) return { login: false };
  }
  return {};
};

export const getPayInfo = async () => {
  const url = `${process.env.REACT_APP_API_URL}/order-status/user/payinfo`;
  const response: ApiResponseType | undefined = await ApiMiddleware({
    payload: {
      url: `${url}`,
      method: "GET",
    },
  });
  if (response && response.status) {
    if (response.status < 400 && response.data) {
      return response.data;
    } else if (response.status === 401) return { login: false };
  }
  return {};
};

export const setAddress = async ({
  orderId,
  address,
  addressDetail,
  zipCode,
  deliveryMessage,
  phone,
}: {
  orderId: string;
  address: string;
  addressDetail: string;
  zipCode: number;
  deliveryMessage: string;
  phone: string;
}) => {
  const url = `${process.env.REACT_APP_API_URL}/order-status/delivery/address`;
  const data = {
    order_id: orderId,
    address: address,
    address_detail: addressDetail,
    zip_code: zipCode,
    delivery_message: deliveryMessage,
    phone,
  };
  const response: ApiResponseType | undefined = await ApiMiddleware({
    payload: {
      url: `${url}`,
      method: "PUT",
      data,
    },
  });
  if (response && response.status) {
    if (response.status < 400 && response.data) {
      return response.data;
    } else if (response.status === 401) return { login: false };
  }
  return {};
};
