import Cookies from "js-cookie";

export const setAccessToken = (token: string) => {
  Cookies.set("access_token", token, {
    secure: true,
    expires: 1,
    sameSite: "strict",
    path: "/",
  });
};

export const getAccessToken = (): string | undefined => {
  return Cookies.get("access_token");
};

export const removeAccessToken = () => {
  Cookies.remove("access_token");
};
