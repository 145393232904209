import axios, { AxiosResponse } from "axios";

interface perfume {
  id: number;
  name: string;
  brand: string;
}
/**
 * 향기 제품 이름 또는 브랜드로 검색
 * @param name 제품 이름이나 브랜드를 string으로 전송
 * @return `data` 제품 검색이 정상적으로 이루어졌을 경우 제품에 대한 정보가 담겨있는 객체
 * @return `login` API 호출에 필요한 토큰을 재발행하는 RefreshToken이 존재하지 않음을 판단하는 객체
 */
export const perfumeFindByName = async (
  name: string
): Promise<Array<perfume>> => {
  const url = `${process.env.REACT_APP_API_URL}/finder/finder/by-name`;
  const params = `query=${name}`;

  try {
    const response: AxiosResponse<Array<perfume>> = await axios.get<
      Array<perfume>
    >(`${url}?${params}`);
    return response?.data;
  } catch (error) {
    return [];
  }
};

/**
 * 향기 제품 이름과 브랜드로 검색
 * @param name 제품 이름을 string으로 전송
 * @param brand 제품 브랜드를 string으로 전송
 * @return `data` 제품 검색이 정상적으로 이루어졌을 경우 제품에 대한 정보가 담겨있는 객체
 * @return `login` API 호출에 필요한 토큰을 재발행하는 RefreshToken이 존재하지 않음을 판단하는 객체
 */
export const perfumeFindByNameAndBrand = async (
  name: string,
  brand: string
): Promise<Array<perfume>> => {
  const url = `${process.env.REACT_APP_API_URL}/finder/finder/by-name-and-brand`;
  const params = `name=${name}&brand=${brand}`;

  try {
    const response: AxiosResponse<Array<perfume>> = await axios.get<
      Array<perfume>
    >(`${url}?${params}`);
    return response?.data;
  } catch (error) {
    return [];
  }
};

/**
 * 향기 제품 아이디로 검색
 * @param id 제품 ID를 number로 전송
 * @param flag 제품의 정보 종류에 대한 flag를 boolean으로 전송
 * @return `data` 제품 검색이 정상적으로 이루어졌을 경우 제품에 대한 정보가 담겨있는 객체
 * @return `login` API 호출에 필요한 토큰을 재발행하는 RefreshToken이 존재하지 않음을 판단하는 객체
 */
export const perfumeFindById = async (id: number, flag: boolean = false) => {
  const url = `${process.env.REACT_APP_API_URL}/finder/finder/by-id`;
  const params = `id=${id}&flag=${flag}`;

  try {
    const response = await axios.get(`${url}?${params}`);
    return response?.data;
  } catch (error) {
    return [];
  }
};
