import { useAtom, useAtomValue } from "jotai";
import { recommendButtonLabel, seasonal } from "../../../contents/recommend";
import Button from "../../atoms/button";
import {
  appearanceAtom,
  fragranceTasteAtom,
  imageAtom,
  perfumeProductAtom,
  recommendLevelAtom,
  recommendTitleAtom,
  seasonalCheckedAtom,
  storyAtom,
} from "../../../stores/recommend/atoms";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getRefreshToken } from "../../../utils/refreshToken";

const RecommendButton = () => {
  const navigate = useNavigate();
  const [recommendLevel, setRecommendLevel] = useAtom(recommendLevelAtom);

  const title = useAtomValue(recommendTitleAtom);
  const perfumeProduct = useAtomValue(perfumeProductAtom);
  const seasonalChecked = useAtomValue(seasonalCheckedAtom);
  const fragranceTaste = useAtomValue(fragranceTasteAtom);
  const appearance = useAtomValue(appearanceAtom);
  const story = useAtomValue(storyAtom);
  const image = useAtomValue(imageAtom);

  const [button, setButton] = useState(false);
  const changeButton = () => {
    if (recommendLevel === 0) setButton(true);
    else if (recommendLevel === 1) {
      if (title) setButton(true);
      else setButton(false);
    } else if (recommendLevel === 2) {
      if (perfumeProduct && perfumeProduct.length > 0) setButton(true);
      else setButton(false);
    } else if (recommendLevel === 3) {
      if (fragranceTaste && fragranceTaste.length > 0) setButton(true);
      else setButton(false);
    } else if (recommendLevel === 4) {
      if (appearance && appearance.length > 0) setButton(true);
      else setButton(false);
    } else if (recommendLevel === 5) {
      let check = false;

      seasonalChecked.map((value, index) => {
        if (value) check = true;
        return 0;
      });
      if (check) setButton(true);
      else setButton(false);
    } else if (recommendLevel === 6) {
      if (story.length > 0) setButton(true);
      else setButton(false);
    } else if (recommendLevel === 7) {
      if (image) setButton(true);
      else setButton(false);
    }
  };

  useEffect(() => {
    changeButton();
  }, [
    recommendLevel,
    title,
    perfumeProduct,
    fragranceTaste,
    appearance,
    seasonalChecked,
    story,
    image,
  ]);

  const handleClick = async () => {
    if (recommendLevel === 7) {
      navigate("/custom-order/loading");
    } else {
      button && setRecommendLevel(recommendLevel + 1);
    }
  };

  const authCheck = async () => {
    const token = getRefreshToken();
    if (!token) {
      alert("로그인 정보가 유효하지 않습니다. \n로그인  페이지로 이동합니다.");
      navigate("/login");
    }
  };

  useEffect(() => {
    authCheck();
  }, []);

  return (
    <div className="fixed bottom-0 w-full sm:w-[450px] p-6 bg-white">
      <Button
        className={`w-full ${
          button ? "bg-black cursor-pointer" : "bg-[#dddddd] cursor-not-allowed"
        } text-white rounded-2xl h-[70px] text-[20px]`}
        handleClick={handleClick}
      >
        {recommendButtonLabel[recommendLevel]}
      </Button>
      {!recommendLevel && (
        <div
          onClick={() => {
            navigate("/");
          }}
          className="text-[16px] text-center mt-3 cursor-pointer"
        >
          지금은 괜찮아요
        </div>
      )}
    </div>
  );
};

export default RecommendButton;
