import { useRef, useState } from "react";

interface defaultProps {
  image: File | undefined;
  setImage: React.Dispatch<React.SetStateAction<File | undefined>>;
}

const ImageInput = ({ image, setImage }: defaultProps) => {
  const [imageUrl, setImageUrl] = useState<string>("/images/uploadImage.png");
  const fileInput = useRef<HTMLInputElement>(null);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // 업로드 된 파일 받아옴
    // 파일이 정상적으로 업로드되지 않은 경우 종료
    const file = event.target.files?.[0];
    if (!file) return;
    if (file.type.startsWith("image/")) {
      setImage(file);
    }

    // 파일리더 객체 생성
    // 받아온 파일을 DataURL 형식으로 변환(Base64 인코딩 문자열)
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    // 파일 읽기가 완료되었을 때 이미지 변경 콜백함수 실행
    fileReader.onload = (event) => {
      if (typeof event.target?.result === "string") {
        setImageUrl(event.target?.result);
      }
    };
  };
  return (
    <>
      <button
        className="w-full rounded-2xl aspect-square overflow-hidden"
        onClick={() => {
          fileInput.current?.click();
        }}
      >
        <img className="w-full" src={imageUrl} alt="" />
      </button>
      <input
        className="hidden"
        type="file"
        accept="image/*"
        // capture="environment"
        onChange={handleChange}
        ref={fileInput}
      />
    </>
  );
};

export default ImageInput;
