import { useEffect } from "react";
import { authKakao } from "../../../api/auth";
import { useNavigate, useSearchParams } from "react-router-dom";

const Auth = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  const authKakaoLogin = async () => {
    if (code) {
      const state = await authKakao(code);
      if (!state) {
        alert("로그인에 문제가 밸생했습니다. \n로그인페이지로 이동합니다.");
        navigate("/login");
      } else {
        navigate("/");
      }
    }
    // try {
    //   const res = await axios.get(
    //     "https://devmode.memoriascent.com/auth/health"
    //   );
    //   console.log(res);
    // } catch (error) {
    //   console.log(error);
    // }
  };

  useEffect(() => {
    authKakaoLogin();
  }, []);

  return <></>;
};

export default Auth;
