import { ReactNode, useEffect, useState } from "react";
import { orderAgreementList } from "../../../contents/orderAgreement";
import OrderAgreementItem from "../../molecules/order/orderAgreementItem";
import OrderAgreementTotal from "../../molecules/order/orderAgreementTotal";
import { useAtom } from "jotai";
import { agreementTotalAtom } from "../../../stores/order/atoms";

interface AgreementType {
  check: boolean;
  label: string;
  content: ReactNode;
  open: boolean;
}

const OrderAgreementList = () => {
  const [agreementTotal, setAgreementTotal] = useAtom(agreementTotalAtom);
  const [agreementList, setAgreementList] = useState<Array<AgreementType>>([
    {
      check: false,
      label: orderAgreementList[0].label,
      content: orderAgreementList[0].content,
      open: false,
    },
    {
      check: false,
      label: orderAgreementList[1].label,
      content: orderAgreementList[1].content,
      open: false,
    },
  ]);

  /// 데이터를 전역으로 관리하기 때문에 페이지에 접근했을 때 초기화 진행
  useEffect(() => {
    setAgreementTotal(false);
  }, []);

  const handleClickTotal = () => {
    const newAgreementList: Array<AgreementType> = [];
    agreementList.map((value) => {
      const newAgreement: AgreementType = {
        check: !agreementTotal,
        label: value.label,
        content: value.content,
        open: value.open,
      };
      newAgreementList.push(newAgreement);
    });
    setAgreementList(newAgreementList);
  };

  const handleClickItem = (click: string, indexNum: number) => {
    const newAgreementList: Array<AgreementType> = [];
    if (click === "check") {
      agreementList.map((value, index) => {
        if (index === indexNum) {
          const newAgreement: AgreementType = {
            check: !value.check,
            label: value.label,
            content: value.content,
            open: value.open,
          };
          newAgreementList.push(newAgreement);
        } else {
          newAgreementList.push(value);
        }
      });
      setAgreementList(newAgreementList);
    } else {
      agreementList.map((value, index) => {
        if (index === indexNum) {
          const newAgreement: AgreementType = {
            check: value.check,
            label: value.label,
            content: value.content,
            open: !value.open,
          };
          return newAgreementList.push(newAgreement);
        } else {
          return newAgreementList.push(value);
        }
      });
      setAgreementList(newAgreementList);
    }
  };

  useEffect(() => {
    let check = true;
    agreementList.map((value) => {
      if (!value.check) return (check = false);
    });
    setAgreementTotal(check);
  }, [agreementList]);

  return (
    <div className="flex flex-col">
      <OrderAgreementTotal
        check={agreementTotal}
        label="모든 약관에 동의할게요."
        handleClick={handleClickTotal}
      />
      <div className="divide-y border-y">
        {agreementList.map((value, index) => {
          return (
            <OrderAgreementItem
              check={value.check}
              label={value.label}
              content={value.content}
              open={value.open}
              index={index}
              handleClick={handleClickItem}
            ></OrderAgreementItem>
          );
        })}
      </div>
    </div>
  );
};

export default OrderAgreementList;
