import { atom } from "jotai";

const orderIdAtom = atom<string>("");

const orderProductNameAtom = atom<string>("");
const orderProductCountAtom = atom<number>(0);
const orderProductPriceAtom = atom<number>(0);

const customerNameAtom = atom<string>("");
const customerEmailAtom = atom<string>("");
const customerPhoneAtom = atom<string>("");

const deliveryNameAtom = atom<string>("");
const deliveryZipCodeAtom = atom<number>(0);
const deliveryAddressAtom = atom<string>("");
const deliveryAddressDetailAtom = atom<string>("");
const deliveryPhoneAtom = atom<string>("");
const deliveryMemoAtom = atom<string>("");

const agreementTotalAtom = atom<boolean>(false);

const paymentAgreeAtom = atom(false);

export {
  orderIdAtom,
  orderProductNameAtom,
  orderProductCountAtom,
  orderProductPriceAtom,
  customerNameAtom,
  customerEmailAtom,
  customerPhoneAtom,
  deliveryNameAtom,
  deliveryZipCodeAtom,
  deliveryAddressAtom,
  deliveryAddressDetailAtom,
  deliveryPhoneAtom,
  deliveryMemoAtom,
  agreementTotalAtom,
  paymentAgreeAtom,
};
