import CheckBoxButton from "../atoms/checkBoxButton";

interface defaultProps {
  items: Array<string>;
  checked: Array<boolean>;
  setChecked: React.Dispatch<React.SetStateAction<boolean[]>>;
}
const CheckBoxGroup = ({ items, checked, setChecked }: defaultProps) => {
  const handleClick = (eventIndex: number) => {
    const newChecked: Array<boolean> = [];

    checked.map((value, index) => {
      return newChecked.push(index === eventIndex ? !value : value);
    });
    setChecked(newChecked);
  };
  return (
    <div className="flex flex-row w-full justify-between content-start flex-wrap">
      {items.map((value, index) => {
        return (
          <CheckBoxButton
            key={index}
            index={index}
            handleClick={handleClick}
            state={checked[index]}
          >
            {value}
          </CheckBoxButton>
        );
      })}
    </div>
  );
};

export default CheckBoxGroup;
