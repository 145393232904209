interface defaultProps {
  width?: number | string;
  height?: number | string;
  src: string;
  alt?: string;
}

const Image = ({ width, height, src, alt }: defaultProps) => {
  return <img width={width} height={height} src={src} alt={alt ? alt : ""} />;
};

export default Image;
