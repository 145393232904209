import { useSearchParams } from "react-router-dom";
import {
  orderDeliveryPrice,
  orderSalePrice,
} from "../../../contents/orderTestData";
import OrderAmountItem from "../../molecules/order/orderAmountItem";
import { useAtomValue } from "jotai";
import { orderProductPriceAtom } from "../../../stores/order/atoms";

const OrderAmountInfo = () => {
  const productPrice = useAtomValue(orderProductPriceAtom);

  return (
    <div className="flex flex-col space-y-[8px]">
      <OrderAmountItem
        label="선택 금액"
        value={`${productPrice.toLocaleString("ko-KR")} 원`}
      />
      <OrderAmountItem
        label="할인 금액"
        value={`${orderSalePrice.toLocaleString("ko-KR")} 원`}
      />
      <OrderAmountItem
        label="배송비"
        value={`${orderDeliveryPrice.toLocaleString("ko-KR")} 원`}
      />
      <OrderAmountItem
        label="총 결제 금액"
        value={`${(
          productPrice -
          orderSalePrice +
          orderDeliveryPrice
        ).toLocaleString("ko-KR")} 원`}
        color={true}
      />
    </div>
  );
};

export default OrderAmountInfo;
