import Input from "../atoms/input";

interface defaultProps {
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
}

const TitleInput = ({ title, setTitle }: defaultProps) => {
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };
  return (
    <div className="p-4 bg-[#D9D9D9] h-[50px]">
      <Input
        value={title}
        handleInputChange={handleTitleChange}
        placeholder="이곳에서 입력해주세요."
      />
    </div>
  );
};

export default TitleInput;
