interface defaultProps {
  index: number;
  focus: boolean;
}

const StateBar = ({ index, focus }: defaultProps) => {
  return (
    <div
      className={`h-[8px] w-full ${
        focus ? "bg-black" : "border border-[#D9D9D9]"
      }`}
    ></div>
  );
};

export default StateBar;
