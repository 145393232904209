import OrderElementLabel from "../../molecules/order/orderElementLabel";
import OrderInputElement from "../../molecules/order/orderInputElement";
import { useAtom } from "jotai";
import {
  customerEmailAtom,
  customerNameAtom,
  customerPhoneAtom,
} from "../../../stores/order/atoms";
import { useEffect } from "react";
import { getPayInfo, getUserInfo } from "../../../api/customOrder";

export const formatPhoneNumber = (phoneNumber: string): string => {
  // 숫자만 추출
  const numbers = phoneNumber.replace(/[^0-9]/g, "");

  // +82로 시작하는 경우 010으로 변환
  const formattedNumber = numbers.startsWith("82")
    ? "0" + numbers.slice(2)
    : numbers;

  // 최종적으로 11자리가 아닌 경우 원래 번호 반환
  if (formattedNumber.length !== 11) {
    return phoneNumber;
  }

  return formattedNumber;
};

const OrderCustomerInput = () => {
  const [customerName, setCustomerName] = useAtom(customerNameAtom);
  const [customerEmail, setCustomerEmail] = useAtom(customerEmailAtom);
  const [customerPhone, setCustomerPhone] = useAtom(customerPhoneAtom);

  const getCustomerInfo = async () => {
    const data = await getPayInfo();
    console.log(data);

    setCustomerName(data.name);
    setCustomerEmail(data.email);
    setCustomerPhone(formatPhoneNumber(data.phone));
  };

  /// 데이터를 전역으로 관리하기 때문에 페이지에 접근했을 때 초기화 진행
  /// 주문자 정보의 경우 API가 완성되면 추가하고 초기화 진행
  useEffect(() => {
    setCustomerName("");
    setCustomerEmail("");
    setCustomerPhone("");
    getCustomerInfo();
  }, []);

  const handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
    setState: React.Dispatch<any>
  ) => {
    setState(event.target.value);
  };

  return (
    <div className="pt-[32px]">
      <OrderElementLabel label="주문자 정보 입력" src="/svgs/order/user.svg" />
      <div className="pt-[16px] space-y-[16px] border-t mt-[16px]">
        <OrderInputElement
          label="주문자"
          handleInputChange={(event) => {
            handleInputChange(event, setCustomerName);
          }}
          value={customerName}
          placeholder="성함을 입력해주세요."
          essential={true}
        />
        <OrderInputElement
          label="이메일"
          handleInputChange={(event) => {
            handleInputChange(event, setCustomerEmail);
          }}
          value={customerEmail}
          placeholder="이메일을 입력해주세요."
          essential={true}
        />
        <OrderInputElement
          label="휴대폰 번호"
          handleInputChange={(event) => {
            handleInputChange(event, setCustomerPhone);
          }}
          value={customerPhone}
          placeholder="- 없이 입력해주세요."
          essential={true}
        />
      </div>
    </div>
  );
};

export default OrderCustomerInput;
