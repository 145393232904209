import { ApiResponseType } from "../types/api";
import { ApiMiddleware } from "../middleware/middleware";

interface confirmPaymentParams {
  paymentKey: string;
  orderId: string;
  amount: string;
}

export const validateOrderId = async ({ orderId }: { orderId: number }) => {
  const url = `${process.env.REACT_APP_API_URL}/pay/payment/order-validate`;
  const params = `order_id=${orderId}`;

  const response: ApiResponseType | undefined = await ApiMiddleware({
    payload: {
      url: `${url}?${params}`,
      method: "GET",
    },
  });
  if (response && response.status) {
    if (response.status < 400 && response.data) {
      return response.data;
    } else if (response.status === 401) return { login: false };
  }
  return {};
};

export const getCustomerId = async () => {};

export const preRequest = async ({
  orderId,
  amount,
}: {
  orderId: string;
  amount: string;
}) => {
  const url = `${process.env.REACT_APP_API_URL}/pay/payment/pre-request`;
  if (!url) {
    console.error("REACT_APP_ORDER_API_URL is not defined");
    return { success: false, code: 500 };
  }

  const response = await ApiMiddleware({
    payload: {
      url,
      method: "PUT",
      data: {
        order_id: orderId,
        amount: Number(amount),
      },
    },
  });
  if (response && response.status) {
    if (response.status < 400 && response.data) {
      return response.data;
    } else if (response.status === 401) return { login: false };
  }
};

export const confirmPayment = async ({
  paymentKey,
  orderId,
  amount,
}: confirmPaymentParams) => {
  const url = `${process.env.REACT_APP_API_URL}/pay/payment/approve`;
  if (!url) {
    console.error("REACT_APP_ORDER_API_URL is not defined");
    return { success: false, code: 500 };
  }

  const response = await ApiMiddleware({
    payload: {
      url,
      method: "PUT",
      data: {
        payment_key: paymentKey,
        order_id: orderId,
        amount: Number(amount),
      },
    },
  });
  if (response && response.status) {
    if (response.status < 400 && response.data) {
      return response.data;
    } else if (response.status === 401) return { login: false };
  }
};
