import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import Image from "../atoms/image";
import Button from "../atoms/button";

interface defaultProps {
  children: ReactNode;
  cart?: boolean;
  home?: boolean;
}

const ContentHeader = ({
  children,
  cart = false,
  home = false,
}: defaultProps) => {
  const navigate = useNavigate();

  return (
    <header className="flex flex-row items-center h-[75px] px-5 border-b border-[#FF7300]">
      <Button
        className="w-[48px] h-[48px] flex justify-center items-center border rounded-[8px] border-[#E7E7E7]"
        handleClick={() => {
          if (home) navigate("/");
          else navigate(-1);
        }}
      >
        <Image src="/svgs/leftArrow.svg" width={24} height={24} />
      </Button>
      <div className="grow flex justify-center items-center text-[18px]">
        {children}
      </div>
      {cart ? (
        <Button
          className="w-[48px] h-[48px] flex justify-center items-center border rounded-[8px] border-[#E7E7E7]"
          handleClick={() => {
            navigate(-1);
          }}
        >
          <Image src="/svgs/cart.svg" width={24} height={24} />
        </Button>
      ) : (
        <div className="w-[48px] flex justify-center items-center"></div>
      )}
    </header>
  );
};

export default ContentHeader;
