import MainTemplate from "../templates/mainTemplate";
import MainHeader from "../organisms/mainHeader";
import NavigationBar from "../organisms/navigationBar";
import MainContent from "../organisms/main/mainContent";
import MainFooter from "../organisms/main/mainFooter";

const Main = () => {
  // const navigate = useNavigate();
  return (
    <MainTemplate
      contentHeader={<MainHeader />}
      navigationBar={<NavigationBar />}
      mainContent={<MainContent />}
      mainFooter={<MainFooter />}
    />
  );
};

export default Main;
