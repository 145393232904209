import Image from "../atoms/image";
import LoginButton from "../molecules/loginButton";

interface defaultProps {
  handleLogin: () => void;
}

const LoginForm = ({ handleLogin }: defaultProps) => {
  return (
    <div className="flex flex-col items-center p-6 space-y-[20px] mt-20">
      <Image width={150} height={150} src="/logo.png" />
      <div className="text-center leading-tight text-xl">
        별도의 회원가입 없이
        <br />
        다양한 서비스를 빠르게 시작하세요
      </div>
      <LoginButton
        src="/svgs/kakao-svgrepo-com.svg"
        bg="FEE500"
        color="000000"
        label="카카오로 시작하기"
        handleClick={handleLogin}
      />
    </div>
  );
};

export default LoginForm;
