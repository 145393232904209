// x버튼을 클릭하면 제거되도록

import { ReactNode } from "react";

interface defaultProps {
  index: number;
  children: ReactNode;
  handleClickLabel: (index: number) => void;
}

const TagLabel = ({ index, children, handleClickLabel }: defaultProps) => {
  return (
    <button
      className="flex flex-row text-[16px] border border-black min-w-[80px] px-4 py-2 text-black mr-2 mb-2"
      onClick={(event) => {
        event.stopPropagation();
        handleClickLabel(index);
      }}
    >
      <div className="w-full text-center">{children}</div>
    </button>
  );
};

export default TagLabel;
