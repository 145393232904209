interface defaultProps {
  value: string;
  type?: string;
  handleInputChange: React.ChangeEventHandler<HTMLInputElement>;
  handleKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
}

const Input = ({
  value,
  type = "text",
  handleInputChange,
  handleKeyDown,
  placeholder,
  className,
}: defaultProps) => {
  return (
    <input
      className={
        "bg-transparent outline-none px-2 w-full text-[14px] placeholder:text-black " +
        className
      }
      value={value}
      type={type}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      placeholder={placeholder}
    />
  );
};

export default Input;
