import { useEffect, useState } from "react";
import CheckBoxGroup from "../../molecules/checkBoxGroup";
import TagGroup from "../../molecules/tagGroup";
import AreaInput from "../../atoms/areaInput";
import TitleInput from "../../molecules/titleInput";
import PerfumeFinder from "../../molecules/perfumeFinder";
import CustomOrderItem from "../../molecules/customOrderItem";
import { useAtom, useAtomValue } from "jotai";
import {
  appearanceAtom,
  fragranceTasteAtom,
  imageAtom,
  perfumeProductAtom,
  recommendLevelAtom,
  recommendTitleAtom,
  seasonalCheckedAtom,
  storyAtom,
} from "../../../stores/recommend/atoms";
import { seasonal } from "../../../contents/recommend";
import ImageInput from "../../molecules/imageInput";
import { AnimatePresence, motion } from "framer-motion";

const RecommendForm = () => {
  const recommendLevel = useAtomValue(recommendLevelAtom);
  const [title, setTitle] = useAtom(recommendTitleAtom);
  const [searchInput, setSearchInput] = useState<string>("");
  const [perfumeProduct, setPerfumeProduct] = useAtom(perfumeProductAtom);
  const [seasonalChecked, setSeasonalChecked] = useAtom(seasonalCheckedAtom);
  const [fragranceTaste, setFragranceTaste] = useAtom(fragranceTasteAtom);
  const [appearance, setAppearance] = useAtom(appearanceAtom);
  const [story, setStory] = useAtom(storyAtom);
  const [image, setImage] = useAtom(imageAtom);

  const handleStoryChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setStory(event.target.value);
  };

  const contents = [
    <div className="w-full flex justify-center">
      <img src="/images/artworkImage1.png" className="w-[70%] mt-10" />
    </div>,
    <TitleInput title={title} setTitle={setTitle}></TitleInput>,
    <PerfumeFinder
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      perfumeProduct={perfumeProduct}
      setPerfumeProduct={setPerfumeProduct}
    />,
    <TagGroup value={fragranceTaste} setValue={setFragranceTaste} />,
    <TagGroup value={appearance} setValue={setAppearance} />,
    <CheckBoxGroup
      items={seasonal}
      checked={seasonalChecked}
      setChecked={setSeasonalChecked}
    />,
    <AreaInput
      value={story}
      handleAreaChange={handleStoryChange}
      placeholder="이곳에서 입력해주세요."
    ></AreaInput>,
    <ImageInput image={image} setImage={setImage} />,
  ];

  useEffect(() => {}, [seasonalChecked, fragranceTaste]);

  return (
    <div className="p-6 pb-[110px]">
      <AnimatePresence mode="wait">
        <motion.div
          key={recommendLevel}
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -30 }}
          transition={{
            duration: 0.5,
            delay: 0.4,
            ease: "easeInOut",
          }}
        >
          <CustomOrderItem>{contents[recommendLevel]}</CustomOrderItem>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default RecommendForm;
