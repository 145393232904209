import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import Layout from "../../layout";
import StateBar from "../../atoms/statusBar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactNode, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { transformOrigin } from "html2canvas/dist/types/css/property-descriptors/transform-origin";
import {
  perfumeRecommendCommentAtom,
  perfumeRecommendGenderAtom,
  perfumeRecommendLevelAtom,
  perfumeRecommendStoryAtom,
} from "../../../stores/perfumeRecommend/atoms";

const PerfumeRecommend = () => {
  const [params] = useSearchParams();
  const retry = params.get("retry");
  const navigate = useNavigate();
  const [perfumeRecommendGender, setPerfumeRecommendGender] = useAtom(
    perfumeRecommendGenderAtom
  );
  const [perfumeRecommendStory, setPerfumeRecommendStory] = useAtom(
    perfumeRecommendStoryAtom
  );
  const [recommendLevel, setRecommendLevel] = useAtom(
    perfumeRecommendLevelAtom
  );

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleArrowClick = () => {
    setIsOpen(!isOpen);
  };

  const handleButtonClick = () => {
    if (recommendLevel === 2) {
      navigate("/recommendPerfume/loading");
    } else setRecommendLevel(recommendLevel + 1);
  };

  useEffect(() => {
    if (retry) {
      setRecommendLevel(2);
      setTimeout(() => {
        setIsOpen(true);
      }, 2000);
    } else {
      setRecommendLevel(0);
      setPerfumeRecommendStory("");
    }
  }, []);

  const Contents: ReactNode[] = [
    <div className="flex flex-col px-6 py-2">
      <div className="h-[160px] text-[20px] font-bold">
        반갑습니다
        <br />
        <br />딱 1분이면 AI가
        <br />
        적절한 향수를 추천해줄거에요!
      </div>
      <div className="w-full flex justify-center">
        <img
          src="https://storage.studioforu.com/uploads/recommend_Image1_691d8dcaf3.webp"
          className="w-[70%] mt-4"
        />
      </div>
    </div>,
    <div className="flex flex-col px-6 py-2">
      <div className="h-[100px] text-[20px] font-bold">성별을 알려주세요.</div>
      <CheckBoxGroup />
    </div>,
    <div className="flex flex-col px-6 py-2">
      <div className="h-[100px] text-[20px] font-bold">
        어떤 이미지의 향수가
        <br />
        필요하신가요?
      </div>
      <textarea
        className="whitespace-pre-wrap text-lg outline-none p-4 w-full h-[200px] bg-white border border-black rounded-[8px] text-[16px] placeholder:text-[16px] placeholder:text-black"
        value={perfumeRecommendStory}
        onChange={(event) => {
          setPerfumeRecommendStory(event.target.value);
        }}
        placeholder="이곳에서 입력하세요"
      />
      <div className="flex flex-col p-4 text-[16px] text-[#666666] mt-4 border-b border-[#666666]">
        <button
          className="flex flex-row justify-between"
          onClick={handleArrowClick}
        >
          <div>입력 예시</div>

          <svg
            style={{
              transform: isOpen ? "rotateX(180deg)" : "rotateX(0deg)",
            }}
            className="w-6 h-6 transition-transform duration-300"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>
      </div>
      <div className="overflow-hidden">
        <div
          className="transition-[max-height] duration-300 ease-in-out"
          style={{
            maxHeight: isOpen ? "500px" : "0px", // 실제 내용 높이에 맞게 조절
          }}
        >
          <div className="p-5 text-[16px] text-[#666666] border-b border-[#666666]">
            저는 20대 중반 남자고 현재 조말론 우드세이지 씨솔트라는 향수를
            쓰고있습니다. 바디워시와 샴푸는 바질향을 사용하고 있고, 무화과와
            같은 상큼하고 단 향을 좋아합니다. 집에서는 두 개의 디퓨저를 사용하고
            있구요, 하나는 일반적인 세제향이 약간 묻어있는 향기가 나고, 화장실에
            사용하는 디퓨저는 '양재동 꽃시장'이라는 상큼한 향을 사용하고
            있습니다. 평소에는 향수를 사용하지는 않고, 특별한 날에만 사용합니다.
          </div>
        </div>
      </div>
    </div>,
  ];

  return (
    <Layout>
      <div className="flex flex-col items-center w-full pb-[100px]">
        <RecommendStatusBar />
        <AnimatePresence mode="wait">
          <motion.div
            key={recommendLevel}
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -30 }}
            transition={{
              duration: 0.5,
              delay: 0.4,
              ease: "easeInOut",
            }}
          >
            <RecommendContent>{Contents[recommendLevel]}</RecommendContent>
          </motion.div>
        </AnimatePresence>
        <RecommendButton
          level={recommendLevel}
          handleClick={handleButtonClick}
        />
      </div>
    </Layout>
  );
};

const RecommendStatusBar = () => {
  const [recommendLevel, setRecommendLevel] = useAtom(
    perfumeRecommendLevelAtom
  );

  return (
    <StateGroup comment={perfumeRecommendCommentAtom} focus={recommendLevel} />
  );
};

const CheckBoxGroup = () => {
  const [perfumeRecommendGender, setPerfumeRecommendGender] = useAtom(
    perfumeRecommendGenderAtom
  );
  return (
    <div className="flex flex-row w-full justify-between content-start flex-wrap">
      <button
        onClick={() => {
          setPerfumeRecommendGender("man");
        }}
        className={`flex flex-col justify-between items-center border w-[48%] pt-6 pb-2 px-6 mb-6 ${
          perfumeRecommendGender === "man"
            ? "bg-black text-white"
            : "bg-[#D9D9D9] text-black"
        }`}
      >
        <img
          src="https://storage.studioforu.com/uploads/man_cb06995c99.webp"
          className="rounded-full w-full aspect-square"
          alt=""
        ></img>
        <div className="text-[18px] font-bold my-6">남자</div>
      </button>
      <button
        onClick={() => {
          setPerfumeRecommendGender("woman");
        }}
        className={`flex flex-col justify-between items-center border w-[48%] pt-6 pb-2 px-6 mb-6 ${
          perfumeRecommendGender === "woman"
            ? "bg-black text-white"
            : "bg-[#D9D9D9] text-black"
        }`}
      >
        <img
          src="https://storage.studioforu.com/uploads/woman_0ee607c69b.webp"
          className="rounded-full w-full aspect-square"
          alt=""
        ></img>
        <div className="text-[18px] font-bold my-6">여자</div>
      </button>
    </div>
  );
};

const RecommendContent = ({ children }: { children: ReactNode }) => {
  return <div className="flex flex-col">{children}</div>;
};

const RecommendButton = ({
  level,
  handleClick,
}: {
  level: number;
  handleClick: () => void;
}) => {
  const navigate = useNavigate();
  const perfumeRecommendGender = useAtomValue(perfumeRecommendGenderAtom);
  const perfumeRecommendStory = useAtomValue(perfumeRecommendStoryAtom);
  const setRecommendLevel = useSetAtom(perfumeRecommendLevelAtom);
  const listId = localStorage.getItem("listId");

  const [button, setButton] = useState(false);
  useEffect(() => {
    if (level === 0) setButton(true);
    else if (level === 1 && perfumeRecommendGender) setButton(true);
    else if (level === 2 && perfumeRecommendStory.length > 3) setButton(true);
    else setButton(false);
  }, [level, perfumeRecommendGender, perfumeRecommendStory]);
  return (
    <div className="fixed bottom-0 w-full sm:w-[450px] px-6 pb-6 pt-3 bg-white">
      {level === 0 && listId && (
        // localStorage에 데이터가 있을 경우에만 보이도록
        <div
          onClick={() => {
            navigate(`/recommendPerfume/list?id=${listId}`);
          }}
          className="text-[16px] text-center mb-4 cursor-pointer"
        >
          추천 받은 내용 보러가기
        </div>
      )}
      <div className="flex flex-row h-[70px] space-x-4">
        {level !== 0 && (
          <button
            className="min-w-[70px] flex justify-center items-center border rounded-[16px] border-[#E7E7E7]"
            onClick={() => {
              setRecommendLevel(level - 1);
            }}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
        )}
        <button
          className={`grow text-white rounded-2xl h-[70px] text-[20px] ${
            button ? "bg-black" : "bg-[#dddddd]"
          }`}
          onClick={handleClick}
          disabled={button ? false : true}
        >
          {level === 0 ? "시작하기" : "다음 단계로"}
        </button>
      </div>
    </div>
  );
};

interface stateGroupProps {
  comment: Array<string>;
  focus: number;
}

const StateGroup = ({ comment, focus }: stateGroupProps) => {
  return (
    <div className="flex flex-row w-full space-x-1 p-5">
      {comment.map((value, index) => {
        return (
          <div
            key={index}
            className={`flex flex-col space-y-1 ${
              focus === index ? "grow" : "w-[20%]"
            }`}
          >
            <StateBar focus={focus === index} index={index} />
            {focus === index && <div>{value}</div>}
          </div>
        );
      })}
    </div>
  );
};

export default PerfumeRecommend;
