import { useLocation, useNavigate } from "react-router-dom";
import Button from "../atoms/button";
import Image from "../atoms/image";
import Label from "../atoms/label";
import { getRefreshToken } from "../../utils/refreshToken";

interface defaultProps {
  focus: boolean;
  image: string;
  focusImage: string;
  label: string;
  url: string;
}

const NavigationLabel = ({
  focus,
  image,
  focusImage,
  label,
  url,
}: defaultProps) => {
  const token = getRefreshToken();
  const navigate = useNavigate();
  const location = useLocation();
  const path = "/" + location.pathname.split("/").slice(1, 2);

  const handleClick = async () => {
    if (
      (url === "/user" ||
        url === "/custom-order" ||
        url === "/recommendPerfume") &&
      !token
    ) {
      alert("로그인 정보가 유효하지 않습니다. \n로그인  페이지로 이동합니다.");
      navigate("/login");
    } else if (url !== path) navigate(url);
  };
  return (
    <Button
      handleClick={handleClick}
      className={`${
        focus ? "grow" : "w-[50px]"
      } flex flex-col items-center space-y-[8px] `}
    >
      <div
        className={`rounded-[8px] border w-full p-[8px] flex justify-center items-center ${
          focus ? "border-[#E82900] bg-[#EB6143]" : "border-[#C7C7C7] bg-white"
        }`}
      >
        <div
          className={`h-[30px] ${
            focus ? "grow" : "aspect-square"
          }  flex items-center justify-center`}
        >
          <Image src={focus ? focusImage : image} height={20} />
        </div>
      </div>
      <Label
        className={`text-[12px] ${focus ? "text-[#EB6143]" : "text-[#888888]"}`}
      >
        {label}
      </Label>
    </Button>
  );
};

export default NavigationLabel;
