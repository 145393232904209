import Cookies from "js-cookie";

export const setRefreshToken = (token: string) => {
  Cookies.set("refresh_token", token, {
    secure: true,
    expires: 14,
    sameSite: "strict",
    path: "/",
  });
};

export const getRefreshToken = (): string | undefined => {
  return Cookies.get("refresh_token");
};

export const removeRefreshToken = () => {
  Cookies.remove("refresh_token");
};
